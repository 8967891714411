import buildControllerPhone from 'core/controllers/buildControllerPhone'
import { CONNECTME_STATUS_CLASS } from 'core/constants/thirdParties'
import addEventListener from 'utils/DOM/addEventListener'
import connectMeStore from './connectMeStore'
import handleSubmit from './handleSubmit'
import onUpdateStatus from './onUpdate'
import references from './references'
import { propAccessor } from '@hc-frontend/utils'

function controllerConnectMe({
  placement,
  moduleId = '1205',
  form,
  holder,
  statusElem,
  phoneFieldName = 'phone',
  enabled,
  hcStorage,
  onStart = null,
  onInit = null,
  onCall = null,
  onStatus = null,
  onUpdate = onUpdateStatus
}) {
  const connectMeState = connectMeStore.getState()

  if (connectMeState.modules && connectMeState.modules[placement]) {
    return
  }

  if (!form) {
    form = document.querySelector(`form[data-placement="${placement}"]`)
  }

  if (!holder) {
    holder = document.querySelector(`.g3cm_holder[data-placement="${placement}"]`)
  }

  if (!statusElem) {
    statusElem = document.querySelector(
      `.${CONNECTME_STATUS_CLASS}[data-placement="${placement}"]`
    )
  }
  const phoneCtrl = buildControllerPhone({ input: form[phoneFieldName] })
  phoneCtrl.setValue(propAccessor(hcStorage, 'contact.phone_home', ''))

  references[placement] = {
    phoneCtrl,
    form,
    holder,
    statusElem
  }

  connectMeState.modules[placement] = {
    enabled: enabled || connectMeState.salesCenterAvailable,
    moduleId,
    status: null // '[null|starting|init|connected|completed|failed]'
  }

  connectMeStore.update(connectMeState)
  connectMeStore.registry(onUpdate(placement))

  if (!form.dataset.placement) {
    form.dataset.placement = placement
  }

  addEventListener(form, 'submit', handleSubmit)
}

export default controllerConnectMe
