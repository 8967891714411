import forEach from 'utils/DOM/forEach'
import isArray from 'utils/validation/isArray'

function toggleClass(
  element: StringOrNodeSelector,
  className: string | string[],
  status: undefined | boolean = undefined
) {
  const action = typeof status !== 'boolean' ? 'toggle' : status ? 'add' : 'remove'

  className = isArray(className) ? className : [className]

  forEach(element, (node: HTMLElement) => {
    node && node.classList[action](...className)
  })
}

export default toggleClass
