import Templater from '@hc-frontend/shared-assets/src/js/utils/dom/Templater'
import { CDN_PIVOT } from 'core/constants/endpoints'
import getById from 'utils/DOM/getById'

interface AddHeaderHcLogoParams {
  logo_uri: string
  full_name: string
}

function addHeaderHcLogo(
  config: AddHeaderHcLogoParams = {
    logo_uri: `${CDN_PIVOT}/resources/content/logos/HealthcareComLogo2024.png`,
    full_name: 'Healthcare.com logo'
  }
): void {
  const logoTemplate = Templater(getById('enroll-sublogo').innerText)
  document
    .querySelector('.Header__logo')
    .insertAdjacentHTML('afterend', logoTemplate(config))
}

export default addHeaderHcLogo
