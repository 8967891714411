import getDefaultMember from '@hc-frontend/shared-assets/src/js/sem/storage-manager/getDefaultMember'
import loadData from '@hc-frontend/shared-assets/src/js/sem/storage-manager/loadData'
import getErrorStartDate from 'core/controllers/validations/startDate'
import { INVALID_PROFILE_EXCEPTION } from 'core/constants/exceptions'
import { TEMPORAL_DATA_FROM_QUOTE_KEY } from 'core/constants/storage'
import {
  getDifferenceOnDays,
  dateToString,
  stringToDate,
  alterDate,
  capitalize
} from '@hc-frontend/utils'
import getLocationByZip from 'core/services/getLocationByZip'
import getQuoteByKey from 'core/services/getQuoteByKey'
import resetHcStorage from 'utils/pivot/resetHcStorage'
import sessionData from 'core/sessionData'
import storage from 'proxy-storage'
import { IMember } from '@hc-frontend/entities'

function wipeQuote() {
  throw INVALID_PROFILE_EXCEPTION
}

function parseMember(member: IMember) {
  const returnableMemberCopy = getDefaultMember()

  returnableMemberCopy.dob = member.dob
  returnableMemberCopy.gender = member.gender.toLowerCase()
  returnableMemberCopy.type = capitalize(member.role)

  return returnableMemberCopy
}

async function updateByQuoteKey(
  hcStorage: Pivot.Store,
  key: string
): Promise<Pivot.Store | void> {
  const quoteResponse = await getQuoteByKey(key)

  if (quoteResponse.error || quoteResponse.status === 'error') {
    return wipeQuote()
  }

  const request = quoteResponse.data.request
  const location = (await getLocationByZip(request.location.zip))[0]

  hcStorage.location.county = location.countyName
  hcStorage.location.state_acronym = location.stateAcronym
  hcStorage.location.zip_code = location.zipCode
  hcStorage.location.state = location.stateName
  hcStorage.sdate = request.coverage.startDate
  hcStorage.endDate = request.coverage.endDate
  hcStorage.members = request.members.map(parseMember)

  if (hcStorage.agent_id !== request.agentId) {
    hcStorage.agent_id = request.agentId
    storage.setItem('hc', hcStorage)
    resetHcStorage(hcStorage)
    hcStorage = await loadData(true)
  }

  let nextStartDate
  let nextEndDate

  if (getErrorStartDate(request.coverage.startDate)) {
    const tomorrow = alterDate({ days: 1 })
    nextStartDate = dateToString(tomorrow)

    if (request.coverage.endDate) {
      const startDate = stringToDate(request.coverage.startDate)
      const differenceOnDays = getDifferenceOnDays(tomorrow, startDate)

      nextEndDate = alterDate({
        date: stringToDate(request.coverage.endDate),
        days: differenceOnDays
      })
    }
  }

  sessionData.setData(TEMPORAL_DATA_FROM_QUOTE_KEY, {
    quoteResponse,
    nextStartDate,
    nextEndDate
  })

  return hcStorage
}

export default updateByQuoteKey
