import forEach from 'utils/DOM/forEach'

function replaceTCPA(
  tcpaSelector = 'main-tcpa',
  tcpaDisclaimerSelector = '.tcpa-disclaimer'
) {
  const tcpaNode = document.getElementById(tcpaSelector)
  if (tcpaNode) {
    const tcpaValue = tcpaNode.innerHTML
    const callbackFunction = node => {
      node.innerHTML = tcpaValue
    }
    forEach(tcpaDisclaimerSelector, callbackFunction)
  }
}

export default replaceTCPA

window.replaceTCPA = replaceTCPA
