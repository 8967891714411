import fetchJSONHandleError from 'utils/NET/fetchJSONHandleError'
import { SEM_CONTENT_STATIC } from 'core/constants/endpoints'
import { IS_ENROLL, IS_AGENT } from 'core/constants/environment'

function getLandingContent() {
  const keyword = window.location.pathname
    .split('/')
    .filter(Boolean)
    .splice(IS_ENROLL ? 0 : 1)
    .join('/')
  const product = IS_ENROLL ? 'STM' : IS_AGENT ? 'PH_AGENTS' : 'PH_DTC'
  const item = IS_ENROLL ? keyword : (IS_AGENT ? 'PH_AGENTS' : 'PH_DTC') + `-${keyword}`

  return fetchJSONHandleError({
    // cspell:disable-next-line
    url: `${SEM_CONTENT_STATIC}/content/landingpage/${product}/${item}`,
    responseOnError: {
      data: {}
    }
  })
}

export default getLandingContent
