const environments: EnvTypes[] = ['local', 'qa', 'stg']
let envComputed: EnvTypes

export default function getEnvironment() {
  if (envComputed) return envComputed
  const hostPars = window.location.hostname.split('.')
  envComputed = environments.find(env => hostPars.includes(env)) || 'prd'
  return envComputed
}

type EnvTypes = 'local' | 'qa' | 'stg' | 'prd'
