import showRedirectModal from 'pug/mixins/redirectModal'
import getEnvironment from 'utils/getEnvironment'
import getDTCPort from 'utils/pivot/environment/getDTCPort'
import {
  IS_AGENT,
  IS_SHORT_TERM,
  IS_BRIDGE_TO_MEDICARE,
  IS_DENTAL_AND_VISION,
  IS_FIXED_BENEFIT_MEDICAL
} from 'core/constants/environment'
import {
  type RedirectStatus,
  getAgentCharacteristics,
  convertPathname,
  buildURL
} from './utils'

export default function agents({
  agent,
  isSalesCenterAgency
}: RedirectStatus): RedirectStatus | Promise<RedirectStatus> {
  if (IS_SHORT_TERM || IS_BRIDGE_TO_MEDICARE) {
    const environment = getEnvironment()
    const { pathname, protocol, port, search } = window.location
    const { isPivotAgent, isDTCAgent } = getAgentCharacteristics(
      agent,
      isSalesCenterAgency
    )
    const noRedirectApp = IS_DENTAL_AND_VISION || IS_FIXED_BENEFIT_MEDICAL

    if (!IS_AGENT && isPivotAgent) {
      return { organicRedirect: true }
    }

    const newPathname = convertPathname(pathname, isDTCAgent)

    if (!(newPathname === '' || isPivotAgent || (isDTCAgent && noRedirectApp))) {
      const subdomain = isDTCAgent ? 'enroll.' : 'sales.'
      const newPort = isDTCAgent ? getDTCPort() : port

      const redirectTo = buildURL({
        protocol,
        subdomain,
        environment,
        port: newPort,
        pathname: newPathname,
        search
      })

      return new Promise<RedirectStatus>(resolve =>
        showRedirectModal({
          onHide: () => resolve({ redirectTo })
        })
      )
    }
  }

  return { continue: true }
}
