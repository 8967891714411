import { CONNECTME_ENABLED_CLASS } from 'core/constants/thirdParties'
import toggleClass from 'utils/DOM/toggleClass'

function toggleConnectMeEnabledClass(connectMeState) {
  const { salesCenterAvailable, ready } = connectMeState
  const connectMeEnabled = salesCenterAvailable && ready

  toggleClass(document.body, CONNECTME_ENABLED_CLASS, connectMeEnabled)
}

export default toggleConnectMeEnabledClass
