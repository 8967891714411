import { findApplicant } from '@hc-frontend/business'
import { WebStorage } from 'proxy-storage'

const sessionStorage = new WebStorage('sessionStorage')

function returningUserBar(hcStorage) {
  const applicant = findApplicant(hcStorage.members)
  const dismissedBar = Boolean(sessionStorage.getItem('ph_welcome_dismissed'))

  if (!dismissedBar && applicant && applicant.dob && applicant.gender) {
    import(/* webpackChunkName: "widget/returning-user-bar" */ './render').then(
      returningBar => {
        returningBar.default.render()
      }
    )
  }
}

export default returningUserBar
