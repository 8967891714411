import { getPhoneNumberPromise } from 'core/services/getPhoneNumber'
import savePhoneToStorage from './savePhoneToStorage'
import { CONNECTME } from 'core/constants/thirdParties'
import connectMeStore from './connectMeStore'
import updateStatus from './updateStatus'
import references from './references'
import initCall from './initCall'

function handleSubmit(e) {
  e.preventDefault()
  const form = e.currentTarget
  const placement = form.dataset.placement
  const connectMeState = connectMeStore.getState()
  const phoneCtrl = references[placement].phoneCtrl

  phoneCtrl.validate()
  const phoneStatus = phoneCtrl.getStatus()
  if (!phoneStatus.valid) {
    return
  }
  const phoneNumber = phoneStatus.value

  savePhoneToStorage(phoneNumber)

  if (
    !window.__g3cm ||
    !window.G3ConnectMe ||
    !(window.__g3cm instanceof window.G3ConnectMe)
  ) {
    updateStatus(placement, CONNECTME.unavailable)
    return
  }

  updateStatus(placement, CONNECTME.starting)

  const idLocation = connectMeState.modules[placement].idLocation || placement

  getPhoneNumberPromise({ idLocation }).then(resp => {
    if (!resp.phone || !resp.phone.promo_number) {
      updateStatus(placement, CONNECTME.unavailable)
      return false
    }

    initCall({
      placement,
      invocaPhone: resp.phone,
      phoneNumber
    })

    // this is a stand-alone script, initPage() is not called before
    const smartlySDK = window.hc.smartlySDK
    if (smartlySDK) {
      smartlySDK.ready().then(() => {
        smartlySDK.track('ph_conversion_call')
        smartlySDK.publish()
      })
    }
  })
}

export default handleSubmit
