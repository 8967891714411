import setHideMenuHandler from 'pug/mixins/header/burger/setHideMenuHandler'
import addHeaderHcLogo from 'core/DOM/addHeaderHcLogo'
import isHcStmDomain from 'utils/pivot/isStmHcDomain'
import isHCAgent from 'utils/pivot/agent/isHCAgent'
import 'bootstrap/js/src/modal'
import { IS_LOCAL_ENVIRONMENT } from 'core/constants/environment'
import getById from 'utils/DOM/getById'
import toggleClass from 'utils/DOM/toggleClass'

function setUpHeader(chatInteractionId, agentInformation) {
  setHideMenuHandler()

  if ((isHcStmDomain() || isHCAgent(agentInformation)) && !IS_LOCAL_ENVIRONMENT) {
    addHeaderHcLogo()
    toggleClass('#Header__logo', 'withSublogo', true)
  }

  if (chatInteractionId) {
    $(document)
      .off('click.chat')
      .on('click.chat', '.js-chat', e => {
        e.preventDefault()
        const chat = document.getElementById('five9-maximize-button')
        chat?.click()
      })
  }
}

export default setUpHeader
