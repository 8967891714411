import querySelectorAll from 'utils/DOM/querySelectorAll'

function removeClassNoneFromPhoneElement() {
  const elements = querySelectorAll('.promo_number')

  let index
  for (index = 0; index < elements.length; index++) {
    elements[index].classList.remove('d-none')
  }
}

export default removeClassNoneFromPhoneElement
