import loadSession from 'core/services/sessionManagement/load'

export default async function readFromService(
  sessionId?: string
): Promise<Pivot.Session | undefined> {
  if (!sessionId) {
    return undefined
  }

  const result = await loadSession(sessionId)

  if (!result.error) {
    return result as Pivot.Session
  } else {
    console.error('[x] Failed to load session', sessionId, result.error)
    return undefined
  }
}
