import { fetchJSON } from '@hc-frontend/utils';

import type { IPersonalInfoResponseData } from './fetch-personal-info-response';

/**
 * GET call to fetch personal info for a companion user
 *
 * @param email - The email id for which personal info is to be fetched
 * @returns - The response from companion backend users endpoint
 *
 * @public
 *
 * @example
 *
 * ```ts
 * const personalInfo = await fetchPersonalInfoByEmailService('test@mail.com');
 * ```
 */
export async function fetchPersonalInfoByEmailService(
  email: string
): Promise<IPersonalInfoResponseData | null> {
  const { parsedBody } = await fetchJSON<IPersonalInfoResponseData>(
    `https://${process.env['NEXT_PUBLIC_IDENTITY_SERVICE_DOMAIN']}/identity/api/v1/users?email=${email}`
  );
  return parsedBody?.data ? parsedBody : null;
}
