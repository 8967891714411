import updateDOM from './updateDOM'

function setValueText(state, value) {
  if (value && !state.loading) {
    state.error = false
    state.valid = true
    state.input.value = value

    updateDOM(state)
  }
}

export default setValueText
