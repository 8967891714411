import { fetchJSON } from '@hc-frontend/utils';

import { semContentURI } from '../config/endpoints';
import type { ICallCampaignPayload } from './fetch-call-campaign-service-payload';
import type { ICallCampaignResponse } from './fetch-call-campaign-service-response';

/**
 * Fetch a call campaign from sem content. @See https://github.com/healthcarecom/sem-content#get-campaign-of-calls
 *
 * @param payload - The payload to send to sem content endpoint
 * @returns - The response from sem content endpoint
 *
 * @public
 *
 * @example
 *
 * ```ts
 * const payload = { ... }
 * const phone = await fetchCallCampaignService(payload);
 * ```
 */
export async function fetchCallCampaignService(
  payload: ICallCampaignPayload
): Promise<ICallCampaignResponse | null> {
  const { parsedBody } = await fetchJSON<ICallCampaignResponse>(
    `${semContentURI()}/call/campaign`,
    {
      body: payload,
    }
  );
  return parsedBody?.data ? parsedBody : null;
}
