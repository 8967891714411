/**
 * Checks if a given plan is at least one of the given types
 *
 * @param {CorePlan} plan: Plan to check
 * @param {Array<PlanType> } planType: list of plans to be checked
 * @returns {boolean} true if plan is one of the types sent in PlanType, else it's false
 */
function isPlanType<T extends CorePlan>(plan: T, ...planType: number[]): boolean {
  return planType.includes(plan.productId)
}

export default isPlanType
