import store from 'utils/store/withMiddleware'
import storage from 'proxy-storage'

function savePhoneToStorage(phoneNumber) {
  const { hcStorage } = store.getState()
  hcStorage.contact.phone_home = phoneNumber

  storage.setItem('hc', hcStorage)
  store.update({ hcStorage })
}

export default savePhoneToStorage
