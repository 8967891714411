import Settings from '@hc-frontend/shared-assets/src/js/utils/settings'

export const IS_LOCAL_ENVIRONMENT = /development|test/i.test(process.env.NODE_ENV)
export const IS_PROD = Settings.isProd

export type Environment = 'prod' | 'stg' | 'qa'

let env: Environment
if (Settings.isProd) env = 'prod'
else if (Settings.isQa || IS_LOCAL_ENVIRONMENT) env = 'qa'
else env = 'stg'

export const ENV: Environment = env

export const PRODUCT = String(process.env.PRODUCT).toLowerCase()
export const PRODUCT_ENROLL = 'enroll'
export const PRODUCT_FI = 'fixed-benefit-medical'
export const PRODUCT_STM = 'short-term-health-insurance'
export const PRODUCT_BTM = 'bridge-to-medicare'
export const PRODUCT_DENTAL_AND_VISION = 'dental-and-vision'
export const MEDICARE_SUPPLEMENT = 'medicare-supplement'

export const IS_AGENT = process.env.FLOW === 'agent'

export const IS_FIXED_BENEFIT_MEDICAL = PRODUCT === PRODUCT_FI

export const IS_SHORT_TERM = PRODUCT === PRODUCT_STM

export const IS_BRIDGE_TO_MEDICARE = PRODUCT === PRODUCT_BTM

export const IS_ENROLL = PRODUCT === PRODUCT_ENROLL

export const IS_DENTAL_AND_VISION = PRODUCT === PRODUCT_DENTAL_AND_VISION

export const IS_MEDICARE_SUPPLEMENT = PRODUCT === MEDICARE_SUPPLEMENT

export const IS_GENERIC_LANDING = process.env.DOMAIN === 'pivot.landings'

export const GTM_ID = IS_ENROLL ? 'GTM-WFF68ZL' : 'GTM-TSVGZR3' // cspell:disable-line

export const FLOW = IS_ENROLL ? 'enroll' : IS_AGENT ? 'agent' : 'consumer'

export const SALES_CENTER_AGENCY = '58568'

export const HC_AGENCY_ID = '150011'

export const CONSUMER_ORGANIC_AGENT_ID = 2010000

export const DTC_LOCAL_PORT = 3100

export const SHOW_UH_ONE_BANNER_ENROLL_QUOTES = true

/** Flags for enabling multi-carrier on each environment */
const MULTI_CARRIER_ENABLED_QA = true
const MULTI_CARRIER_ENABLED_STG = true
const MULTI_CARRIER_ENABLED_PRODUCTION = true

/**
 * Uses the current environment and the above flag to determine multi-carrier availability
 * @returns A boolean, always false if the environment is not an expected value
 */
const getMultiCarrierAvailability = () => {
  switch (ENV) {
    case 'prod':
      return MULTI_CARRIER_ENABLED_PRODUCTION
    case 'stg':
      return MULTI_CARRIER_ENABLED_STG
    case 'qa':
      return MULTI_CARRIER_ENABLED_QA
    default:
      return false
  }
}

export const MULTI_CARRIER_ENABLED = getMultiCarrierAvailability()

// Amplitude
const pivotHealthLegacyDtcDevelopment = 'client-YaJddG1K72rzPmsMxQ2LfjBef9JPgkd7'
const pivotHealthLegacyAgentsDevelopment = 'client-yjESpxjKAqn8f9FQOLckmGidIlAgBd71'
const pivotHealthLegacyDtcProduction = 'client-geZsxQBUqFWWjp2vy4H56hZPAGzXOce4'
const pivotHealthLegacyAgentsProduction = 'client-6Jck3AafGvzgG85aaukxz7J6cmDK1xkB'

function getAmplitudeDeploymentKey(): string {
  if (IS_AGENT && ENV === 'prod') {
    return pivotHealthLegacyAgentsProduction
  } else if (IS_AGENT) {
    return pivotHealthLegacyAgentsDevelopment
  } else if (ENV === 'prod') {
    return pivotHealthLegacyDtcProduction
  } else {
    return pivotHealthLegacyDtcDevelopment
  }
}

/** API key used for Amplitude experiment. Specific to site and environment so that it can be used for targeting. */
export const AMPLITUDE_DEPLOYMENT_KEY = getAmplitudeDeploymentKey()

/** Default sort order for DTC quotes, used as the value of the `sort` URL parameter */
export const DTC_DEFAULT_SORT = 'deductible'

/** Brochure link for multi-carrier plans */
export const MULTI_CARRIER_BROCHURE_LINK =
  'https://cdn.pivothealth.com/resources/brochures/PH1-3-12_062024.pdf'

/** Default plan ID to show when skipping the quotes page */
const MULTI_CARRIER_DEFAULT_PLAN_QA = 612
const MULTI_CARRIER_DEFAULT_PLAN_PRODUCTION = 552

function getDefaultPlanId(): number {
  if (ENV === 'prod') return MULTI_CARRIER_DEFAULT_PLAN_PRODUCTION
  else return MULTI_CARRIER_DEFAULT_PLAN_QA
}

export const MULTI_CARRIER_DEFAULT_PLAN = getDefaultPlanId()
