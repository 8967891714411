import exposeGlobalFunctionAndExecuteQueue from '@hc-frontend/shared-assets/src/js/utils/dom/exposeGlobalFunctionAndExecuteQueue'

function executeWhenFinishToRender(hcData) {
  exposeGlobalFunctionAndExecuteQueue(callback => {
    if (typeof callback === 'function') {
      setTimeout(function() {
        callback(hcData)
      }, 0)
    }
  }, 'executeWhenFinishToRender')
}

export default executeWhenFinishToRender
