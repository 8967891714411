function doRedirect(path) {
  return new Promise(resolve => {
    window.location.assign(path)
    setTimeout(() => {
      resolve(path)
    }, 5000)
  })
}

export default doRedirect
