import { getLastStartDate } from 'utils/pivot/date/getLastStartDate'
import { REQUIRED_ERROR_MESSAGE } from 'messages'
import { stringToDate, alterDate, getTodayDate } from '@hc-frontend/utils'

function startDate(value: string): string {
  const today = getTodayDate()
  const lastStartDateValue = getLastStartDate()

  const lastStartDate = alterDate({
    date: today,
    days: lastStartDateValue
  })

  const tomorrow = alterDate({
    date: today,
    days: 1
  })

  const date = stringToDate(value)

  if (!date) return REQUIRED_ERROR_MESSAGE

  const dateNumber = date.getTime()

  return dateNumber < tomorrow.getTime() || lastStartDate.getTime() < dateNumber
    ? `Start date must be at least one day after and not exceed ${lastStartDateValue} days from current date.`
    : ''
}

export default startDate
