import fetchJSONHandleError from 'utils/NET/fetchJSONHandleError'
import { BACKEND_PIVOT } from 'core/constants/endpoints'

/**
 * Uses agent id (optional) and state acronym
 * @returns An object of data with max duration of multi-carrier eligibility and boolean value of multi carrier
 */
async function getMultiCarrierEligibility(
  stateAcronym: string,
  agentId: string
): Promise<MultiCarrierEligibilityResponse | JSONValue> {
  return await fetchJSONHandleError({
    url: `${BACKEND_PIVOT}/multi-carrier-eligibility?agent_id=${agentId}&state=${stateAcronym}`,
    responseOnError: {
      data: []
    }
  })
}

type MultiCarrierEligibilityResponse = {
  maxDuration: number
  multiCarrier: boolean
}

export default getMultiCarrierEligibility
