import getById from 'utils/DOM/getById'

function setHideMenuHandler() {
  const headerMenuCheck = getById('header-burger-check')
  document.body.addEventListener('click', e => {
    const isHeader = $(e.target).closest('#Header').length > 0

    if (headerMenuCheck && !isHeader) {
      if (headerMenuCheck.checked) {
        headerMenuCheck.checked = false
      }
    }
  })
}

export default setHideMenuHandler
