import showRedirectModal from 'pug/mixins/redirectModal'
import { IS_ENROLL } from 'core/constants/environment'
import getEnvironment from 'utils/getEnvironment'
import {
  type RedirectStatus,
  convertPathname,
  getAgentCharacteristics,
  buildURL
} from './utils'

export default function enroll({
  agent,
  isSalesCenterAgency
}: RedirectStatus): RedirectStatus | Promise<RedirectStatus> {
  if (IS_ENROLL) {
    const env = getEnvironment()
    const environment = env === 'prd' ? '' : `${env}.`
    const { pathname, protocol, port, search } = window.location
    const newPathname = convertPathname(pathname, false)

    const { isDTCAgent, isPivotAgent } = getAgentCharacteristics(
      agent,
      isSalesCenterAgency
    )

    if (newPathname === '' || isDTCAgent) {
      return { continue: false }
    }

    const subdomain = isPivotAgent ? environment || 'www.' : `sales.${environment}`
    const newPort = environment === 'local' ? '3135' : port
    const redirectTo = buildURL({
      protocol,
      subdomain,
      port: newPort,
      pathname: newPathname,
      search
    })

    return new Promise<RedirectStatus>(resolve =>
      showRedirectModal({
        onHide: () => resolve({ redirectTo })
      })
    )
  }

  return { continue: true }
}
