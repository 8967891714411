import storage from 'proxy-storage'
import { countChildren, findApplicant, findSpouse } from '@hc-frontend/business'
import { dateToString } from '@hc-frontend/utils'
import { PRODUCT } from 'core/constants/environment'
import getSalesChannel from 'utils/pivot/environment/getSalesChannel'

const PIVOTHEALTH = 'PivotHealth'

export function getGenericBlueshiftParams() {
  const hcStorage = storage.getItem('hc')

  const {
    members,
    contact,
    location,
    healthcareSession,
    healthcareUserId,
    utm,
    agent_id,
    agency_id,
    bt_sid,
    pivotPlan
  } = hcStorage

  const applicant = findApplicant(members)

  const [dobMonth, dobDay, dobYear] = applicant.stringDOB.split('/')
  // cspell:disable
  return {
    email: contact.email_address,
    household: members.length - 1,
    spouse_included: !!findSpouse(members),
    childrens_included: countChildren(members),
    firstname: contact.first_name,
    lastname: contact.last_name,
    birth_dayofmonth: dobDay,
    birth_month: dobMonth,
    birth_year: dobYear,
    phone_number: contact.phone_home,
    gender: applicant.gender,
    state: location.state,
    zip: location.zip_code,
    city: location.city,
    county: location.county,
    hc_user_id: healthcareUserId,
    customer_id: healthcareUserId,
    healthcare_session: healthcareSession,
    eligibility: 'unknown',
    sales_channel: getSalesChannel(),
    in_typ: false, // replace in eligibility and forward
    traffic_source: utm.utm_source,
    agent_id,
    agency_id,
    search_keyword: bt_sid,
    stm_cancelled: true,
    btm_cancelled: true,
    dental_sa_cancelled: true,
    ph_quote_product: pivotPlan?.product
  }
  // cspell:enable
}
function getDomainUserId() {
  const matcher = new RegExp('_sp_id\\.[a-f0-9]+=([^;]+);?')
  const matchedValue = document.cookie.match(matcher)
  if (matchedValue != null) {
    const split = matchedValue[1].split('.')
    return split[0]
  }
  return ''
}

export function getPivotBlueshiftParams() {
  const hcStorage = storage.getItem('hc')
  const { healthcareSession, pivotPlan } = hcStorage

  return {
    product: PIVOTHEALTH,
    domain_user_id: getDomainUserId(),
    quote_product: pivotPlan?.product ?? PRODUCT,
    sales_channel: getSalesChannel(),
    sessionId: healthcareSession,
    on: dateToString(new Date()),
    agent_id: '',
    isc_lead_stage: '',
    isc_lead_status: '',
    coverage_start_date: ''
  }
}

export function getGenericISCNurtureBlueshiftParams() {
  const hcStorage = storage.getItem('hc')
  const { agentInfo = {}, pivotPlan = {} } = hcStorage ?? {}

  return {
    isc_quote_url: '',
    isc_quote_product: pivotPlan?.product,
    isc_quote_date: dateToString(new Date()),
    isc_lead_stage: 'Quote Sent',
    isc_lead_status: 'Working - Contacted',
    agent_name: agentInfo?.first_name,
    agent_last_name: agentInfo?.last_name,
    agent_email: agentInfo?.email,
    agent_phone_number: agentInfo?.phone,
    externalcarrier_sold: false, // cspell:disable-line
    internally_sold: true
  }
}
