export const INVALID_PROFILE_EXCEPTION = 'Invalid Profile'
export const INVALID_PROFILE_URL_KEY = 'invalidProfile'

export const INVALID_SIGNATURE_DOCUMENT_EXCEPTION = 'Invalid Signature Document'
export const INVALID_SIGNATURE_DOCUMENT_URL_KEY = 'invalidSignatureDocument'

export interface AppHandledException {
  _tag: 'AppHandledException'
  type: string
  key: string
  details?: Record<string, string | number>
}
