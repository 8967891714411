import type { FirstName, LastName } from './SingleValues';

/**
 * The member's day of birth.
 *
 * @public
 */
export type DOB = Date;

/**
 * The member's age.
 *
 * @public
 */
export type Age = number;

/**
 * The weight of the person in pounds.
 *
 * @public
 */
export type Weight = number;

/**
 * Feet tall of a person.
 *
 * @public
 */
export type Feet = number;

/**
 * Inches tall of a person.
 *
 * @public
 */
export type Inches = number;

/**
 * Height of a person expressed in feet and inches.
 *
 * @public
 */
export type Height = {
  feet: Feet;
  inches: Inches;
};

/**
 * The unique identifier of a Member.
 *
 * @public
 */
export type MemberId = string;

/**
 * Member type for an app.
 *
 * @public
 */
export enum MemberType {
  Applicant = 'primary',
  Spouse = 'spouse',
  Child = 'child',
}

/**
 * The gender identifier of a Member.
 *
 * @public
 */
export enum Gender {
  Male = 'm',
  Female = 'f',
}

/**
 * The tobacco use identifier of a Member.
 *
 * @public
 */
export enum TobaccoUse {
  Yes = 'y',
  No = 'n',
}

/**
 * The member is a collection of data that is needed for a person to
 * apply for insurance. This is the base for all kind of members.
 *
 * @public
 */
export interface IMember {
  id: MemberId;
  type: MemberType;
  gender: Gender;
  dob: DOB;
  age: Age;
  stringDOB: string;

  tobacco?: TobaccoUse;
  firstName?: FirstName;
  lastName?: LastName;
  weight?: Weight;
  height?: Height;
}

/**
 * Represents the member to whom the insurance policy has been assigned.
 *
 * @public
 */
export interface IApplicant extends IMember {
  type: MemberType.Applicant;
}

/**
 * Represents the spouse of the Applicant.
 *
 * @public
 */
export interface ISpouse extends IMember {
  type: MemberType.Spouse;
}

/**
 * Represents the children of the Applicant.
 *
 * @public
 */
export interface IChild extends IMember {
  type: MemberType.Child;
}

/**
 * Represents all members that are not the Applicant.
 *
 * @public
 */
export interface IDependent extends IMember {
  type: MemberType.Child | MemberType.Spouse;
}
