import type { ExperimentClient } from '@amplitude/experiment-js-client'
import { Experiment } from '@amplitude/experiment-js-client'
import { AMPLITUDE_DEPLOYMENT_KEY } from 'core/constants/environment'
import { trackExperimentView } from '@hc-frontend/third-party-snowplow'

import type { InitExperimentClientParams } from './types'

/** Returns a singleton instance of the Amplitude client. This can safely be called multiple times. */
function initAmplitudeClient(): ExperimentClient {
  return Experiment.initialize(AMPLITUDE_DEPLOYMENT_KEY, {
    automaticExposureTracking: false,
    exposureTrackingProvider: {
      track: exposure => {
        // Only track experiments that have a variant set considering deployment, split source and segmentation
        if (exposure.variant)
          trackExperimentView({
            experimentName: exposure.flag_key,
            variantName: exposure.variant,
            splitSource: 'amplitude'
          })
      }
    }
  })
}

/**
 * Creates an experiment client interface to interact with the Amplitude experiment client.
 * This allows to initialize the client, get the instance and the cached user properties.
 *
 * @returns An object with methods to interact with the experiment client.
 */
export function createExperimentClient() {
  let instance: ExperimentClient | undefined
  let userProperties:
    | Record<string, string | number | boolean | Array<string | number | boolean>>
    | undefined

  return {
    /**
     * Initializes the experiment client with the provided user properties.
     * @param params.domainUserId The domain user ID to use for the experiment client.
     * @param params.userProperties The user properties to use for the experiment client.
     * @param params.overrideInstance Whether to override the existing instance if it exists.
     *
     * @returns The initialized experiment instance client.
     */
    init: async (params: InitExperimentClientParams) => {
      if (instance && !params.overrideInstance) {
        return instance
      }

      userProperties = params.userProperties
      instance = initAmplitudeClient()
      await instance.start({
        user_id: params.domainUserId,
        user_properties: userProperties
      })

      return instance
    },

    /**
     * Gets the initialized experiment instance client. It throws an error if the client has not been initialized.
     * @returns The initialized experiment instance client.
     */
    getInstance: () => {
      if (!instance) {
        throw new Error('Experiment client has not been initialized')
      }
      return instance
    },

    /**
     * Gets the cached user properties. It throws an error if the client has not been initialized.
     * @returns The cached user properties.
     */
    getUserProperties: () => {
      if (!instance) {
        throw new Error('Experiment client has not been initialized')
      }
      return userProperties
    }
  }
}

export const experimentClient = createExperimentClient()
