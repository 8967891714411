import vanillaTextMask from 'vanilla-text-mask/dist/vanillaTextMask'
import noop from '@hc-frontend/shared-assets/src/js/utils/fp/noop'

import getLocationByZip from 'core/services/getLocationByZip'
import updateDOM from './updateDOM'
import setErrorMessage from './setErrorMessage'
import setValueText from './setValueText'
import getById from 'utils/DOM/getById'

const EMPTY_ERROR = 'Please enter your 5 digit ZIP code.'
const INVALID_ERROR = 'Please provide a valid ZIP code.'

function callEnd(state, response) {
  state.callEndLoading({
    valid: state.valid,
    value: state.input.value,
    response
  })
}

function getLocation(state) {
  getLocationByZip(state.input.value).then(response => {
    state.loading = false
    if (response.error || response.length === 0) {
      setErrorMessage(state, INVALID_ERROR)
    } else {
      state.valid = true
      state.error = false
    }
    updateDOM(state)
    state.input.disabled = false
    callEnd(state, response)
  })
}

function validate(state) {
  if (!state.loading) {
    if (state.input.value.length === 5) {
      state.loading = true
      state.input.disabled = true
      state.callStartLoading()
      getLocation(state)
    } else {
      setErrorMessage(state, EMPTY_ERROR)
    }

    updateDOM(state)
  }
}

function buildControllerZip({ input, callStartLoading = noop, callEndLoading = noop }) {
  const name = input.name
  const state = {
    input,
    callStartLoading,
    callEndLoading,
    wrap: getById(`wrap-${name}`),
    errorWrap: getById(`error-${name}`),
    valid: false,
    loading: false,
    error: false,
    enabledOnBlur: false
  }

  vanillaTextMask({
    inputElement: input,
    mask: [/\d/, /\d/, /\d/, /\d/, /\d/],
    guide: false
  })

  input.addEventListener('input', e => {
    if (e.target.value.length === 5) {
      validate(state)
    } else if (state.error || state.valid) {
      state.valid = false
      state.error = false
      updateDOM(state)
    }
  })

  return {
    setValue: value => {
      setValueText(state, value)
      callEnd(state)
    },
    getStatus: () => ({
      valid: state.valid,
      value: state.input.value,
      loading: state.loading
    }),
    validate: () => {
      validate(state)
    },
    enableOnBlur: () => {
      if (!state.enabledOnBlur) {
        state.enabledOnBlur = true
        input.addEventListener('blur', () => {
          if (!state.valid) {
            validate(state)
          }
        })
      }
    }
  }
}

export default buildControllerZip
