import getURLParams from 'utils/DOM/getURLParams'

import { experimentClient } from './experimentClient'
import type { GetVariantArgs, VariantGetter } from './types'

function fromQueryParams(): VariantGetter {
  return experimentKey => {
    const params = getURLParams()
    const expKeyInQueryParams = `exp-${experimentKey}`

    return {
      source: 'query-params',
      value: params[expKeyInQueryParams]
    }
  }
}

function fromExpClient(): VariantGetter {
  return experimentKey => {
    const experiment = experimentClient.getInstance()
    const variant = experiment.variant(experimentKey)?.value

    return {
      source: 'exp-client',
      value: variant
    }
  }
}

function fromFallback(fallback: string | undefined): VariantGetter {
  return () => ({
    source: 'fallback',
    value: fallback
  })
}

const gettersReducer =
  (experimentKey: string) =>
  (
    finalResult: undefined | ReturnType<VariantGetter>,
    source: boolean | VariantGetter
  ) => {
    if (finalResult) return finalResult
    if (typeof source === 'function') {
      const localResult = source(experimentKey)
      if (localResult.value) return localResult
    }
  }

/**
 * Get the user's variant for the given experiment or feature flag.
 * The variant can be retrieved from the query parameters, the experiment client or a fallback value.
 *
 * `initExperimentClient` should have been called already before calling this function.
 */
export function getVariant({
  experimentKey,
  fallback,
  trackExposure = true,
  useQueryParams = true
}: GetVariantArgs): string | undefined {
  try {
    // Create a list of sources to look for a variant and then reduce it to find the first source that has a variant
    const variantSearch = [
      useQueryParams && fromQueryParams(),
      fromExpClient(),
      fromFallback(fallback)
    ].reduce(gettersReducer(experimentKey), undefined)

    const variant = variantSearch && variantSearch.value

    // Track exposure only if variant was found in the experiment client/fallback and trackExposure is true
    if (
      trackExposure &&
      variant &&
      ['fallback', 'exp-client'].includes(variantSearch.source)
    )
      experimentClient.getInstance().exposure(experimentKey) // Track exposure only if variant was

    return variant
  } catch (e) {
    console.error(`Error getting variant for key ${experimentKey}`, e)
    return fallback
  }
}
