import type { PromNull } from '@hc-frontend/utils';
import { fetchJSON } from '@hc-frontend/utils';

import { getPivotBackendURI } from '..';
import type { IFetchAgentInformationServiceResponse } from '.';

/**
 * Fetch the information of the agent from HC backend
 *
 * @param agentId - The agent id
 * @returns The agent information if found, otherwise null
 *
 * @public
 *
 * @remarks
 * The API sometimes returns code `204` but without data, in this case null is returned
 *
 * @example
 * ```ts
 * const agentInfo = await fetchAgentInformationService('1');
 * ```
 */
async function fetchAgentInformationService(
  agentId: string
): PromNull<IFetchAgentInformationServiceResponse> {
  const agentUrl = `${getPivotBackendURI()}/agent/${agentId}`;
  const { parsedBody } = await fetchJSON<IFetchAgentInformationServiceResponse>(
    agentUrl
  );

  return parsedBody?.data ? parsedBody : null;
}

export { fetchAgentInformationService };
