import saveSessionService from 'core/services/sessionManagement/save'
import { SessionHandlerConfig } from './types'

import createSessionData from './createSessionData'
import readFromQueryParameters from './readFromQueryParameters'
import readFromService from './readFromService'
import readFromState from './readFromState'
import { propAccessor } from '@hc-frontend/utils'

type SessionSource = undefined | Pivot.Session | Promise<Pivot.Session | undefined>

async function merge(
  base: SessionSource,
  update: SessionSource,
  overrideLog = true
): Promise<Pivot.Session | undefined> {
  const resolvedBase = base instanceof Promise ? await base : base
  const resolvedUpdate = update instanceof Promise ? await update : update

  if (!resolvedBase && !resolvedUpdate) {
    return undefined
  }

  // Create a new session and override with base and update data
  const initSessionData = createSessionData(
    propAccessor(resolvedUpdate, 'sessionId') || propAccessor(resolvedBase, 'sessionId')
  )
  const newSession = [
    ...Object.entries(resolvedBase || {}),
    ...Object.entries(resolvedUpdate || {})
  ].reduce((acc, [key, value]) => {
    if (value) {
      acc[key] = value
    }
    return acc
  }, initSessionData)

  // If we have a base session, we can carry over some data if not overridden is set
  if (!overrideLog && resolvedBase) {
    resolvedBase.createdAt && (newSession.createdAt = resolvedBase.createdAt)
    resolvedBase.updatedAt && (newSession.updatedAt = resolvedBase.updatedAt)
  }

  // Based on configuration, we can merge specific nodes further
  const nodes = ['user', 'browser', 'quote', 'cart']
  nodes.forEach(nodeKey => {
    const baseData = propAccessor(resolvedBase, nodeKey)
    const updateData = propAccessor(resolvedUpdate, nodeKey)
    if (baseData || updateData) {
      newSession[nodeKey] = { ...baseData, ...updateData }
    }
  })

  return newSession
}

export default function sessionHandler({
  useQueryParams,
  useService,
  useState,
  save
}: SessionHandlerConfig) {
  return async (initSession?: SessionSource) => {
    let session: Pivot.Session | undefined = undefined

    // First read the ephemeral session from the initial sources
    // The order is important
    for (const source of [
      initSession,
      useState ? readFromState() : undefined,
      useQueryParams ? readFromQueryParameters() : undefined
    ]) {
      session = await merge(session, source)
    }

    // Then attach the session from the service without overriding
    // the log data since the service is the source of truth
    if (useService)
      session = await merge(
        readFromService(propAccessor(session, 'sessionId')),
        session,
        false
      )

    // Finally, save the session if needed
    if (save && session && session.sessionId) {
      await saveSessionService(session)
    }

    return session
  }
}
