import getCoverageDuration from 'core/services/getCoverageDuration'
import updateStorages from 'core/controllerPlans/updateStorages'
import updateCoverageMessages from './updateCoverageMessages'
import appStateUpdated from 'utils/appStateUpdated'
import store from 'utils/store/withMiddleware'
import isEmpty from 'utils/object/isEmpty'
import once from 'utils/functional/once'
import initPage from 'core/initPage'
import initDOM from './initDOM'
import config from './config'
import './styles.scss'
import toggleClass from 'utils/DOM/toggleClass'
import { STUDENT_LP } from 'sites/routes'
import { TRINET_LP } from 'sites/routes'
import updateMultiCarrierEligibility from 'core/controllerPlans/update-multi-carrier-eligibility'
const LP_STUDENT_CLASS = 'lp-student'
const LP_TRINET_CLASS = '#welcome-trinet'
const LP_NONE_CLASS = 'd-none'

function setCoverageToStore({ hcStorage }) {
  if (hcStorage.location.state_acronym) {
    return getCoverageDuration(hcStorage.location.state_acronym)
  } else {
    return Promise.resolve({})
  }
}

function handleInit({ error: errorCoverage, data: responseCoverage }) {
  const hasCoverage =
    !errorCoverage && responseCoverage && !isEmpty(responseCoverage.coverage)

  store.registry([once(initDOM), updateCoverageMessages, appStateUpdated])
  store.update({ hasCoverage })
  if (window.location.pathname === STUDENT_LP) {
    document.body.classList.add(LP_STUDENT_CLASS)
  } else {
    document.body.classList.remove(LP_STUDENT_CLASS)
  }
  if (window.location.pathname === TRINET_LP) {
    toggleClass(LP_TRINET_CLASS, LP_NONE_CLASS, false)
  }
}

initPage({
  placement: config.PLACEMENT,
  tags: ['Landing']
})
  .then(updateStorages)
  .then(updateMultiCarrierEligibility)
  .then(setCoverageToStore)
  .then(handleInit)
