import { membersToQueryString } from '@hc-frontend/business'
import getPlanAsURL from 'core/controllerPlans/getPlanAsURL'
import getURLParams from 'utils/DOM/getURLParams'
import getAgentLoggedId from 'utils/pivot/agent/getAgentLoggedId'
import isAgentLogged from 'utils/pivot/agent/isAgentLogged'
import store from 'utils/store/withMiddleware'

function getURLProfile({
  addPlanId = true,
  placement,
  salesCenterAgent = false,
  agentId,
  addProfile = true,
  planId,
  stateRef,
  addQuoteKey
} = {}) {
  const state = stateRef || store.getState()
  const hcStorage = state.hcStorage

  let params = [`zip=${hcStorage.location.zip_code}`, `agent_id=${hcStorage.agent_id}`]

  if (isAgentLogged() || salesCenterAgent) {
    params.push(
      // cspell:disable-next-line
      `utm_source=${getAgentLoggedId() || agentId}&utm_medium=emailquote${
        placement ? placement.toLowerCase() : ''
      }`
    )
  } else {
    params.push(`uid=${hcStorage.healthcareUserId}`)
  }

  if (planId) {
    params.push(`planid=${planId}`)
  } else if (addPlanId && state.planId) {
    params.push(getPlanAsURL(state))
  }

  if (hcStorage.sdate) {
    params.push(`sdate=${hcStorage.sdate}`)
  }

  if (hcStorage.endDate) {
    params.push(`endDate=${hcStorage.endDate}`)
  }

  if (state.addOns && state.addOns.length > 0) {
    params.push(`add_on=${state.addOns.join(',')}`)
  }

  if (addProfile) {
    params = params.concat(membersToQueryString(hcStorage.members).split('&'))
  }

  const urlParams = getURLParams()
  const paramsToCopy = ['months', 'maxDuration', 'plans']
  paramsToCopy
    .filter(param => urlParams[param])
    .forEach(param => {
      params.push(`${param}=${urlParams[param]}`)
    })

  if (addQuoteKey && state.quoteKey) {
    params.push(`quoteKey=${state.quoteKey}`)
  }

  return params.join('&')
}

export default getURLProfile
