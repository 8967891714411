import 'intersection-observer'
import invoke from '@hc-frontend/shared-assets/src/js/utils/fp/invoke'
import createElementFromString from 'core/DOM/createElementFromString'
import store from 'utils/store/withMiddleware'
import getById from 'utils/DOM/getById'
import toggleClass from 'utils/DOM/toggleClass'

let localData = null
store.update({
  lazyNodes: []
})

const lazyNodes = []
const lazyBlocks = [
  () => {
    return import(/* webpackChunkName: "landing/lazy-block-2" */ './lazyBlock2')
  },
  () => {
    return import(/* webpackChunkName: "landing/lazy-block-1" */ './lazyBlock1')
  }
]

let count = 0
let sentinel
const maxCount = lazyBlocks.length
let container = getById('lazy-container')

function renderBlock({ container, render }) {
  const newNodes = createElementFromString(render())
  Array.from(newNodes).forEach(node => {
    node.classList.add('fadeIn')
    container.insertBefore(node, sentinel)
    lazyNodes.push(node)
  })
  store.update({ lazyNodes })
}

function loadBlock(entries, observer) {
  if (!container) {
    container = getById('lazy-container')
  }
  if (entries[0].isIntersecting && count < maxCount) {
    toggleClass(container, 'lazy-loading', true)
    lazyBlocks[count]().then(app => {
      renderBlock({
        container,
        render: app.render
      })
      toggleClass(container, 'lazy-loading', false)
      invoke(app.init)(localData)
    })

    count += 1
  }

  if (count >= maxCount) {
    toggleClass(container, 'lazy-container--loaded', true)
    observer.unobserve(sentinel)
  }
}

function lazyLoadContent(data) {
  localData = data
  sentinel = data.sentinel || getById('lazy-sentinel')
  const observer = new IntersectionObserver(loadBlock)
  observer.observe(sentinel)
}

export default lazyLoadContent
