import { IS_ENROLL, IS_LOCAL_ENVIRONMENT, IS_PROD } from 'core/constants/environment'

import {
  CDNPivotURI,
  crmMiddlewareURI,
  getHealthcareApiURI,
  getMessagingServiceURI,
  getPivotBackendURI,
  getSessionManagerURI,
  getValue,
  getValueWithoutQA,
  planServiceURI,
  semContentStaticURI,
  semContentURI
} from '@hc-frontend/services'

export const environment = getValue()
const environmentWithoutQA = getValueWithoutQA()

export const GEO_LOCATION = `https://geolocation.healthcare.com/GeoLocation`
export const SEM_CONTENT = semContentURI()
export const SEM_CONTENT_STATIC = semContentStaticURI()
export const SESSION = getSessionManagerURI()

export const PLAN_SRV = planServiceURI()
export const BACKEND_PIVOT = getPivotBackendURI()
export const MESSAGING_SERVICE = getMessagingServiceURI()
export const CDN_PIVOT = CDNPivotURI()
export const DOMAIN = `https://enroll.${environment}pivothealth.com`
export const PIVOT_DOMAIN = `https://${environment || 'www.'}pivothealth.com`
export const SALES_DOMAIN = `https://sales.${environment}pivothealth.com`
export const HEALTHCARE_DOMAIN = `https://${environmentWithoutQA || 'www.'}healthcare.com`
export const RECOMMENDED = `httpS://backend.${environmentWithoutQA}pivothealth.com/recommend/pivot`
export const PAGE_RANKING = `https://page-ranking.${environmentWithoutQA}pivothealth.com`
export const ENROLL_PROD = `https://enroll.pivothealth.com`
export const PIVOT_PROD = `https://www.pivothealth.com`
export const HC_STM_DOMAIN = `stm.healthcare.com`
export const API_PIVOTHEALTH = `https://api.${environment}pivothealth.com`
export const API_AGENTS_AVAILABILITY = `https://crm-middleware.pivothealth.com`
export const SEARCH_ADS = `https://searchads.healthcare.com`
export const BLUESHIFT_DOMAIN = `${getHealthcareApiURI()}/third-party`
export const EXTERNAL_DOMAIN = `https://external-api.${environment}pivothealth.com/api`
export const APIGW = `https://apigw.${environment}pivothealth.com/api`
export const APPLICATIONS = `https://backend.${environment}pivothealth.com/applications`
export const CURRENT_DOMAIN = IS_ENROLL ? DOMAIN : PIVOT_DOMAIN

export const PIVOT_ORGANIC_DOMAIN = IS_LOCAL_ENVIRONMENT
  ? 'http://local.pivothealth.com:3130'
  : PIVOT_DOMAIN

export const RETURNING_IFRAME_DOMAIN = IS_LOCAL_ENVIRONMENT
  ? 'http://enroll.local.pivothealth.com:3130'
  : DOMAIN

export const RETURNING_IFRAME = `${RETURNING_IFRAME_DOMAIN}/returning-iframe/`

export const CRM_MIDDLEWARE = crmMiddlewareURI()

export const HEALTH_SHERPA = 'https://www.healthsherpa.com/?_agent_id=howard-yeh-dkog0g'

export const FIVE9_CHAT = 'https://app.five9.com/clients/consoles/ChatConsole/index.html'

export const BUY_AFLAC = `https://buy.aflac.com/partnerCampaign/AA31761/HealthCare_Default`

export const SESSION_MGMT_DOMAIN = `https://session-management.${environment}pivothealth.com`

export const EXTERNAL_OR_APIGW_BY_ENV = IS_PROD ? EXTERNAL_DOMAIN : APIGW
