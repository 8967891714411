import warn from '@hc-frontend/shared-assets/src/js/utils/warnings/warn'
import fetchJSONHandleError from 'utils/NET/fetchJSONHandleError'
import sessionCachePromise from 'core/sessionCachePromise'
import { API_PIVOTHEALTH } from 'core/constants/endpoints'

let promise
function getAgentHierarchy(agentId: string) {
  if (promise) {
    return promise
  }

  const agentUplineHierarchy = `${API_PIVOTHEALTH}/agents/AgentUplineHierarchy/${agentId}`

  promise = fetchJSONHandleError({
    url: agentUplineHierarchy,
    responseOnError: {
      data: []
    }
  }).then(results => {
    if (results && results.length > 0) {
      return results.map(a => ({
        agent_id: a.legacyId,
        first_name: a.firstName,
        last_name: a.lastName,
        phone: a.phone,
        address: a.address,
        city: a.city,
        state: a.stateId,
        zip: a.zip,
        email: a.email,
        is_active_flag: a.active,
        phone_extension: a.phoneExtension
      }))
    } else {
      warn(`No hierarchy data has been found for agent: ${agentId}`, {
        tags: ['severity:mid'],
        url: agentUplineHierarchy
      })

      return []
    }
  })

  return promise
}

export default sessionCachePromise(getAgentHierarchy, 'AGENT_HIERARCHY_INFO')
