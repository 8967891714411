import type { Nullable } from '@hc-frontend/utils';

import type { IAppPhone } from './AppPhone';
import type { Email, FirstName, FullName, LastName } from './SingleValues';

/**
 *
 * The agent's id
 *
 * @public
 *
 */
export type AgentId = string;

/**
 *
 * The agency id, useful to determine the type of an agent
 *
 * @public
 *
 */
export type AgencyId = string;

/**
 *
 * The agency of an agent
 *
 * @public
 *
 */
export type Agency = {
  id: AgencyId;
  name: string;
};

/**
 *
 * The channel, useful to determine if the agent is part of a campaign
 *
 * @public
 *
 */
export enum Channel {
  Brokerage = 'Brokerage',
  CallCenter = 'Call Center',
  DTC = 'DTC',
  ECommerceIndy = 'eCommerceIndy',
  SalesCenter = 'Sales Center',
  Unknown = 'Unknown',
  Affiliates = 'Affiliates',
}

/**
 *
 * The agent entity is used to determine the information related with the
 * person who is currently working with a given quote/session.
 *
 * @public
 *
 */
export interface IAgent {
  id: AgentId;
  firstName: Nullable<FirstName>;
  lastName: Nullable<LastName>;
  fullName: Nullable<FullName>;
  phone: Nullable<IAppPhone>;
  email: Nullable<Email>;
  agency: Agency;
  channel: Channel;
  isTest: boolean;
}
