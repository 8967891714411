import executeWhenFinishToRender from 'core/renders/executeWhenFinishToRender'
import updateZipFormFromData from 'core/controllers/zipSearchHandler'
import getCoverageDuration from 'core/services/getCoverageDuration'
import getLandingContent from 'core/services/getLandingContent'
import setScrollToLazyHandler from './setScrollToLazyHandler'
import setScrollToHandler from 'core/DOM/setScrollToHandler'
import returningUserBar from 'pug/mixins/returningUserBar'
import lazyLoadContent from './components/lazyLoadContent'
import individualState from 'core/DOM/individualState'
import updateContent from 'core/DOM/updateContent'
import onOutOfView from 'core/DOM/onOutOfView'
import toggleClass from 'utils/DOM/toggleClass'
import store from 'utils/store/withMiddleware'
import agentStore from 'utils/store/simple'
import isEmpty from 'utils/object/isEmpty'
import getById from 'utils/DOM/getById'
import sidebarConnectMeInit from 'core/exitIntent/connectMe/experiences/sidebar'
import mq from 'core/DOM/matchTablet'
import config from './config'
import { sendLandingViewEvent } from 'core/services/email/blueshift/implementations/landing'

const sentinel = getById('lazy-sentinel')

function handleCallback(state) {
  if (state.response && state.response.length > 0 && state.response[0].stateAcronym) {
    getCoverageDuration(state.response[0].stateAcronym).then(
      ({ error: errorCoverage, data: responseCoverage }) => {
        const hasCoverage = !(errorCoverage || isEmpty(responseCoverage.coverage))
        const { hcStorage } = store.getState()
        hcStorage.location = {
          county: state.response[0].countyName,
          state_acronym: state.response[0].stateAcronym,
          zip_code: state.response[0].zipCode,
          state: state.response[0].stateName
        }
        store.update({ hasCoverage, hcStorage })
      }
    )
  }
}

function initDOM({ hcStorage }) {
  // Setup GTM and add userId to dataLayer
  window.dataLayerPushVariable({
    user_id: hcStorage.healthcareUserId
  })
  updateZipFormFromData({ hcStorage, callback: handleCallback })
  sendLandingViewEvent()
  if (hcStorage.location.state_acronym) {
    individualState(hcStorage.location.state_acronym)
  }

  let lastResultNav
  const backToTop = getById('back-to-top')
  onOutOfView('companies', ({ scrollReachElement }) => {
    if (lastResultNav !== scrollReachElement) {
      lastResultNav = scrollReachElement

      toggleClass(backToTop, 'show', scrollReachElement)
    }
  })

  setScrollToHandler()

  const landingContentPromise = getLandingContent().then(function setContent(
    landingContent
  ) {
    updateContent(landingContent)
    store.update({})
    return landingContent
  })

  lazyLoadContent({
    hcStorage,
    landingContentPromise,
    sentinel
  })

  setScrollToLazyHandler(sentinel)
  returningUserBar(hcStorage)

  executeWhenFinishToRender(hcStorage)

  if (mq.matches) {
    sidebarConnectMeInit({
      startIn: 0,
      isMultiStep: true,
      aggressive: false,
      placement: config.PLACEMENT,
      experienceName: 'ph-inactive-sidebar',
      hcData: hcStorage,
      idLocation: 'connectMe-landingPage',
      enableMouseExitIntent: true,
      cookieName: 'connect-me-landing-viewed'
    })
  }
}

export default initDOM
