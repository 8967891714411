import { STM_DURATION_BASED_PRODUCTS, FIXED } from 'core/constants/products'
import fetchJSONHandleError from 'utils/NET/fetchJSONHandleError'
import { BACKEND_PIVOT } from 'core/constants/endpoints'
import sessionCachePromise from 'core/sessionCachePromise'
import { IS_AGENT } from 'core/constants/environment'

const promisesByState = {}

function getCoverageDuration(state, date) {
  const key = `${state}${date ? `-${date}` : ''}`
  if (promisesByState[key]) {
    return promisesByState[key]
  }

  promisesByState[state] = fetchJSONHandleError({
    url: `${BACKEND_PIVOT}/coverage/${state}?${date ? `date=${date}` : ''}`,
    responseOnError: {
      data: []
    }
  }).then(response => {
    if (!response.data || !response.data.coverage) {
      return response
    }

    let longerPeriod = -Infinity
    let shorterPeriod = Infinity
    const durationsByProduct = response.data.coverage.durationBased
    const countByProduct = response.data.planCount

    const coverages = []
    const maxDurationInDays = STM_DURATION_BASED_PRODUCTS.reduce((accum, current) => {
      const duration = durationsByProduct[current]
        ? durationsByProduct[current].reduce((accumulated, curr) => {
            const durationExpression = curr.split('x')
            let duration
            if (durationExpression.length > 1) {
              duration = +durationExpression[0] * +durationExpression[1]
            } else {
              duration = +curr
              coverages.push(+curr)
            }
            return accumulated > duration ? accumulated : duration
          }, 0)
        : accum

      return accum < duration ? duration : accum
    })
    const coverage = [...new Set(coverages)]

    const existCoverage = coverage.length > 0 || response.data.planCount[FIXED]
    coverage.forEach(period => {
      if (period > longerPeriod) {
        longerPeriod = period
      }

      if (period < shorterPeriod) {
        shorterPeriod = period
      }
    })

    response.data = {
      longerPeriod,
      shorterPeriod,
      coverage,
      durationsByProduct,
      countByProduct,
      existCoverage,
      maxDurationInDays,
      date: response.data.date
    }

    return response
  })

  return promisesByState[state]
}

export default sessionCachePromise(
  getCoverageDuration,
  `COV-${IS_AGENT ? 'AGENT' : 'CONSUMER'}`
)
