import sanitize from '../sanitize'

const sanitizeParams = ([prop, value]) => {
  let sanitizedValue

  switch (prop) {
    case 'planid':
      sanitizedValue = sanitize(value).replace('&gt;', '>')
      break

    default:
      sanitizedValue = sanitize(value)
  }

  return [prop, sanitizedValue]
}

export default sanitizeParams
