import type { JSONObject, PromNull } from '@hc-frontend/utils';
import { fetchJSON } from '@hc-frontend/utils';

import { getSessionManagerURI } from '../config';
import type { IFetchSessionServicePayload } from './fetch-session-service-payload';
import type { IFetchSessionServiceResponse } from './fetch-session-service-response';

/**
 * Fetch a new session or update an existing one
 *
 * @param payload - The payload to send to the session manager
 * @returns The response from the session manager or null if the session manager is not available
 *
 * @public
 *
 * @example
 * ```ts
 * const location = await fetchSessionService(payload);
 * ```
 */
async function fetchSessionService(
  payload: IFetchSessionServicePayload
): PromNull<IFetchSessionServiceResponse> {
  const { parsedBody } = await fetchJSON<IFetchSessionServiceResponse>(
    `${getSessionManagerURI()}/session`,
    {
      body: payload as unknown as JSONObject,
    }
  );

  return parsedBody?.sessionId ? parsedBody : null;
}

export { fetchSessionService };
