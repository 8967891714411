export function getAgentCharacteristics(agent, isSalesCenterAgency) {
  const channelName = agent.channel ? agent.channel.channelName.toLowerCase() : ''

  const isDTCAgent =
    (channelName === 'dtc' || channelName === 'unknown' || channelName === '') &&
    !isSalesCenterAgency

  const isPivotAgent =
    (channelName === 'brokerage' || channelName === 'call center') && !isSalesCenterAgency

  const isSalesAgent = channelName === 'indy sales center'

  return {
    channelName,
    isDTCAgent,
    isPivotAgent,
    isSalesAgent
  }
}
