import phone from '@hc-frontend/shared-assets/src/js/sem/validator/rules/phone'
import baseControllerInput from './baseControllerInput'
import { REQUIRED_ERROR_MESSAGE } from 'messages'

const ERROR_MESSAGE = 'This field is required. Please enter a valid US phone number.'
const OPTIONAL_ERROR_MESSAGE = 'Please enter a valid US phone number.'
const REGEX = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/
]
function buildControllerPhone({ input, enable800 = false }) {
  let optional = false
  const baseController = baseControllerInput({
    getErrorMessage: state => {
      const value = state.input.value.replace(/[)(_]/g, '')

      if (optional && !value.length) {
        return false
      }

      if (!value.length) {
        return REQUIRED_ERROR_MESSAGE
      }

      const response = phone({ value, enable800 })

      return response.isValid ? '' : optional ? OPTIONAL_ERROR_MESSAGE : ERROR_MESSAGE
    },
    maskConfig: {
      mask: REGEX
    }
  })({ input })

  baseController.makeOptional = () => {
    optional = true
    baseController.validate()
  }

  const getStatus = baseController.getStatus
  baseController.getStatus = () => {
    const { valid, value } = getStatus()

    return {
      valid: optional && value.length === 0 ? true : valid,
      value
    }
  }

  return baseController
}

export default buildControllerPhone
