const DEFAULT_SUBDOMAINS = ['local', 'qa', 'stg']

type CleanDomainName = (options: { hostName: string; subdomainsList: string[] }) => string

/**
 * Returns a production ready string skipping test/development subdomains
 *
 * @param options Options
 * @param options.hostName Url to be digested
 * @param options.subdomainsList List of subdomains to be tested and removed from hostName
 * @returns Url clean of instances found in subdomainsList
 */
export const cleanDomainName: CleanDomainName = ({
  hostName,
  subdomainsList = DEFAULT_SUBDOMAINS
}) => {
  subdomainsList.map(subdomain => (hostName = hostName.replace(subdomain + '.', '')))
  return hostName
}
