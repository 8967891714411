import './styles.scss'
import connectMe from 'core/exitIntent/connectMe/components'
import registerModal from 'core/exitIntent/connectMe/components/registerModal'

export default function sidebarConnectMeInit(args: ConnectMeArgs) {
  return connectMe({
    ...args,
    render: registerModal
  })
}
