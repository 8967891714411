import {
  dateToString,
  alterDate,
  stringToDate,
  getDifferenceOnDays
} from '@hc-frontend/utils'

function getValidEndDate(
  startDate: string,
  newStartDate: string,
  endDate: string
): string {
  if (!endDate) return ''

  if (startDate && newStartDate !== startDate) {
    const differenceOnDays =
      getDifferenceOnDays(stringToDate(startDate), stringToDate(newStartDate)) - 1
    return endDate
      ? dateToString(alterDate({ date: stringToDate(endDate), days: differenceOnDays }))
      : endDate
  }

  return endDate
}

export default getValidEndDate
