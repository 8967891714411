import { PIVOT_DOMAIN } from 'core/constants/endpoints'
import getDomainIndex from 'utils/getDomainIndex'
import getPathnameIndex from 'utils/getPathnameIndex'

function buildLoginUrl(forceLogout = false) {
  const domain_index = getDomainIndex()
  const path_index = getPathnameIndex()
  const params = new URLSearchParams(window.location.search)
  params.delete('auth')
  const search = encodeURIComponent(params.toString())
  // we need to logout the agent if the token is already expired
  const logoutParam = forceLogout ? '-logout&' : ''
  return `${PIVOT_DOMAIN}/agent/admin/login/?${logoutParam}href=6d754512-b5d6-4fb3-9efa-f5656589ad03&domain_index=${domain_index}&path_index=${path_index}&search=${search}`
}

export default buildLoginUrl
