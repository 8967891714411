import { getTodayDate, stringToDate, getDifferenceOnDays } from '@hc-frontend/utils'
import isEpicPlan from 'utils/pivot/plan/isEpicPlan'
import getURLParams from 'utils/DOM/getURLParams'
import store from 'utils/store/withMiddleware'

function getContextDates() {
  const today = getTodayDate()
  const URLParams = getURLParams()
  const firstAugust = stringToDate(`08/01/2024`) as Date
  const thirtyFirstAugust = stringToDate(`08/31/2024`) as Date
  const firstJanuary = stringToDate(`01/01/2025`) as Date
  const thirdSeptember = stringToDate(`09/03/2024`) as Date

  return {
    today,
    thirtyFirstAugust,
    firstJanuary,
    firstAugust,
    thirdSeptember,
    quoteDate: URLParams['quote-date']
  }
}

const checkForPlanConditions = () => {
  const state = store.getState()
  const plan = state.planId && state.plans ? state.plans[state.planId] : undefined

  const coverageForEpic = !plan || isEpicPlan(plan)

  return { coverageForEpic }
}

function getLastStartDate() {
  const { today, firstJanuary, firstAugust, thirdSeptember, thirtyFirstAugust } =
    getContextDates()
  const { coverageForEpic } = checkForPlanConditions()

  let limitDate: Date | undefined

  // Limit the current plans before August 31st in order to restrict the purchase of new plans
  if ((today < thirtyFirstAugust && !coverageForEpic) || today < firstAugust)
    limitDate = thirtyFirstAugust

  // From August 1st to September 3rd and for epic plans, the limit date is January 1st
  if (today >= firstAugust && today < thirdSeptember && coverageForEpic)
    limitDate = firstJanuary

  if (limitDate) {
    return getDifferenceOnDays(today, limitDate) - 1
  }

  // After custom conditions, the default limit date is 120.
  // The minus one is to consider the start date as the next day.
  return 119
}

function getQuoteDate() {
  const { quoteDate } = getContextDates()

  return quoteDate
}

export { getLastStartDate, getQuoteDate }
