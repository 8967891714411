import Templater from '@hc-frontend/shared-assets/src/js/utils/dom/Templater'
import textContent from 'utils/DOM/textContent'
import getById from 'utils/DOM/getById'
const template = Templater(getById('dynamic-state-template').innerText)

function showDynamicState(state = '', noCoverage = false) {
  textContent('.js-coverage-message', template({ state, noCoverage }))
}

function updateCoverageMessages({ hasCoverage, hcStorage }) {
  if (hcStorage.location.state) {
    if (hasCoverage) {
      textContent('.js-location-state', ` in ${hcStorage.location.state}`)
      showDynamicState(hcStorage.location.state)
    } else {
      showDynamicState('', true)
      textContent('.js-location-state', '')
    }
  } else {
    textContent('.js-location-state', '')
    showDynamicState('')
  }
}

export default updateCoverageMessages
