import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
import 'bootstrap/js/src/collapse'
import jquery from 'jquery'
import * as proxyStorage from 'proxy-storage'
import 'url-search-params-polyfill'

import { findApplicant, findChildren } from '@hc-frontend/business'
import utils from '@hc-frontend/shared-assets/src/js/hc-utils'
import content from '@hc-frontend/shared-assets/src/js/sem/flow-content'
import printPhoneNumber from '@hc-frontend/shared-assets/src/js/sem/flow-content/printPhoneNumber'
import session from '@hc-frontend/shared-assets/src/js/sem/session-manager'
import storage from '@hc-frontend/shared-assets/src/js/sem/storage-manager'
import storePhoneNumber from '@hc-frontend/shared-assets/src/js/sem/storage-manager/storePhoneNumber'
import getESTHour from '@hc-frontend/shared-assets/src/js/utils/dates/getESTHour'
import exposeGlobalFunctionAndExecuteQueue from '@hc-frontend/shared-assets/src/js/utils/dom/exposeGlobalFunctionAndExecuteQueue'
import initRaygun from '@hc-frontend/shared-assets/src/js/utils/raygun'
import urlParameter from '@hc-frontend/shared-assets/src/js/utils/url/urlParameter'

import { fromPhoneE164, onlyDigits } from '@hc-frontend/utils'

import commandHandler from 'core/commandHandler'
import {
  FLOW,
  IS_AGENT,
  IS_BRIDGE_TO_MEDICARE,
  IS_ENROLL,
  IS_FIXED_BENEFIT_MEDICAL,
  IS_SHORT_TERM,
  PRODUCT
} from 'core/constants/environment'
import loadConnectMe from 'core/controllerConnectMe/loadConnectme'
import removeClassNoneFromPhoneElement from 'core/DOM/removeClassNoneFromPhoneElement'
import setUpAgentsHeader from 'core/DOM/setUpAgentsHeader'
import setUpHeader from 'core/DOM/setUpHeader'
import { showPhoneDash } from 'core/DOM/showPhoneDash'
import updateAdaDisclaimer from 'core/DOM/updateAdaDisclaimer'
import getContext from 'core/initPage/getContext'
import getPhoneNumber from 'core/services/getPhoneNumber'
import { onClose } from 'core/services/onClose'

import headerPhoneModal from 'pug/mixins/headerModal'
import loadJornayaScript from 'pug/mixins/jornaya/loadScript'
import removeElements from 'utils/DOM/removeElements'
import setAttribute from 'utils/DOM/setAttribute'
import toggleClass from 'utils/DOM/toggleClass'
import getEnvironment from 'utils/getEnvironment'
import isAgentLogged from 'utils/pivot/agent/isAgentLogged'
import getProdRedirectLink from 'utils/pivot/environment/getProdRedirectLink'
import isHcStmDomain from 'utils/pivot/isStmHcDomain'
import { convertToMember } from 'utils/pivot/member/convertToMember/convertToMember'
import getURLProfile from 'utils/pivot/member/getURLProfile'
import { storeSanitizeInterceptor } from 'utils/store/sanitizeInterceptors'
import store from 'utils/store/withMiddleware'

import { experimentClient } from 'core/experiment'
import { sendWebVitalsMetric } from 'core/services/webVitals'
import { registerGeneralSpListeners } from 'sites/enroll.pivothealth.com/snowplow-triggers/general'
import { trackingWebVitals } from 'utils/webVitals/trackingWebVitals'
import initSmartly from './absmartly'
import checkForRedirects from './check-for-redirects'
import type { InitPageConfiguration } from './definitions'
import initSnowplow from './initSnowplow'
import {
  getMemberInterceptor,
  setMemberInterceptor
} from './interceptors/memberInterceptors'
import sessionHandler from 'core/controllers/sessionHandler'

const { pageView, tracker } = initSnowplow()

proxyStorage.WebStorage.interceptors('getItem', storeSanitizeInterceptor)
proxyStorage.WebStorage.interceptors('getItem', getMemberInterceptor)
proxyStorage.WebStorage.interceptors('setItem', setMemberInterceptor)

window.hc = Object.assign({}, window.hc, utils, {
  getESTHour,
  sem: {
    content,
    session,
    storage
  }
})

window.$ = jquery
window.jQuery = jquery
window.proxyStorage = proxyStorage
window.getURLProfile = getURLProfile

// ---------------------------------

function responseAgent(agentInformation: PlainObject = {}) {
  const promoNumber = agentInformation.phone
    ? `+1${onlyDigits(agentInformation.phone)}`
    : ''

  let promoNumberFormatted = promoNumber ? fromPhoneE164(promoNumber) : ''
  promoNumberFormatted +=
    IS_AGENT && agentInformation.extension ? ' ext. ' + agentInformation.extension : ''

  const phone = {
    link_invoca: '',
    promo_number: promoNumber,
    promo_number_formatted: promoNumberFormatted
  }

  agentInformation.promo_number = phone.promo_number
  agentInformation.promo_number_formatted = phone.promo_number_formatted

  return { phone, agentInformation }
}

// ---------------------------------

async function initHeader(hcStorage, chatInteractionId) {
  const promise = IS_AGENT
    ? setUpAgentsHeader(hcStorage).then(responseAgent)
    : getPhoneNumber(hcStorage)

  return promise.then(async ({ phone, agentInformation }) => {
    printPhoneNumber(phone)
    showPhoneDash(phone)
    store.update({ ringPoolPhoneNumber: phone })

    if (IS_ENROLL || (IS_FIXED_BENEFIT_MEDICAL && !IS_AGENT)) {
      loadJornayaScript()
      loadConnectMe().then(() => {
        headerPhoneModal(hcStorage)
      })
    }

    removeClassNoneFromPhoneElement()
    storePhoneNumber({ data: phone })
    setUpHeader(chatInteractionId, agentInformation)

    const currentStorage = proxyStorage.default.getItem('hc')
    const agentInfo = {
      first_name: agentInformation.first_name,
      last_name: agentInformation.last_name,
      phone: agentInformation.phone,
      email: agentInformation.email,
      is_test_agent: agentInformation.is_test_agent
    }
    currentStorage.agency_id = agentInformation.agency_id
    hcStorage.agency_id = agentInformation.agency_id
    hcStorage.agentInfo = agentInfo

    if (agentInformation.channel) {
      hcStorage.channel = agentInformation.channel
      currentStorage.channel = hcStorage.channel
    }

    proxyStorage.default.setItem('hc', currentStorage)

    if (isAgentLogged()) {
      toggleClass('#header__menu__medicare', 'd-none', false)
    }

    const environment = getEnvironment()
    if (environment === 'qa' || environment === 'stg') {
      toggleClass('#header_env_banner', 'd-none', false)

      setAttribute(
        '.Header__environment-banner-link',
        'href',
        getProdRedirectLink(hcStorage)
      )
    }

    return agentInformation
  })
}

// ---------------------------------

function runWithHCData(hcStorage) {
  exposeGlobalFunctionAndExecuteQueue(callback => {
    if (typeof callback === 'function') {
      callback(hcStorage)
    }
  }, 'loadData')

  if (
    ['hc.com', 'hc.org'].includes(hcStorage.utm.utm_source) &&
    hcStorage.utm.utm_campaign === 'typ'
  ) {
    document.body.classList.add('internal')
  }

  exposeGlobalFunctionAndExecuteQueue(commandHandler.dispatch, 'hcDynamic')
  return hcStorage
}
const whitelist = ['Eligibility']

const pickHcStorageAndSignedDetails = async ({
  signatureDocument,
  updateLocationFromApplicantContext,
  clearStorage
}) => {
  const { hcStorage: loadedHcStorage, ...restSignatureDocument } = signatureDocument || {}
  const hcStorage =
    loadedHcStorage ||
    (await getContext({
      updateLocation: updateLocationFromApplicantContext,
      clearStorage
    }))
  const signedDetails = Object.keys(restSignatureDocument).length
    ? restSignatureDocument
    : undefined

  return { hcStorage, signedDetails }
}

const getUpdatedSession = sessionHandler({
  useQueryParams: true,
  useService: true
})

async function initPage({
  placement = '',
  tags = [],
  chatInteractionId = null,
  updateLocationFromApplicantContext = false,
  clearStorage = false,
  signatureDocument
}: InitPageConfiguration) {
  trackingWebVitals(sendWebVitalsMetric)
  initRaygun(rg4js =>
    rg4js('withTags', [
      'company:ph',
      `env:${getEnvironment()}`,
      `service:pivot-health`,
      `flow:${FLOW}`,
      `page:${PRODUCT}/${placement}`
    ])
  )
  if (whitelist.indexOf(tags[0]) >= 0) {
    onClose()
  }

  const { hcStorage, signedDetails } = await pickHcStorageAndSignedDetails({
    signatureDocument,
    updateLocationFromApplicantContext,
    clearStorage
  })

  hcStorage.session = await getUpdatedSession(hcStorage.session)

  // update data to storage
  hcStorage.members = convertToMember(hcStorage.members)

  const applicant = findApplicant(hcStorage.members)

  if (IS_SHORT_TERM || IS_ENROLL) {
    if (applicant && applicant.age && applicant.age < 18) {
      hcStorage.childOnly = true
      hcStorage.members = [
        findApplicant(hcStorage.members),
        ...findChildren(hcStorage.members)
      ].filter(Boolean)
    }
  }

  const addOn = urlParameter('add_on')
  if (addOn) {
    hcStorage.addOn = (addOn + '').split(',').map(id => +id)
  }

  hcStorage.placement = placement
  hcStorage.period = '' + hcStorage.period
  hcStorage.policies = '' + urlParameter('policies') || hcStorage.policies || '1'

  hcStorage.IS_AGENT = IS_AGENT
  hcStorage.IS_ENROLL = IS_ENROLL
  hcStorage.IS_SHORT_TERM = IS_SHORT_TERM
  hcStorage.IS_BRIDGE_TO_MEDICARE = IS_BRIDGE_TO_MEDICARE
  hcStorage.IS_FIXED_BENEFIT_MEDICAL = IS_FIXED_BENEFIT_MEDICAL

  if (chatInteractionId) {
    hcStorage.chatInteractionId = chatInteractionId
  } else {
    delete hcStorage.chatInteractionId
  }

  proxyStorage.default.setItem('hc', hcStorage)

  const hour = getESTHour()

  if (IS_ENROLL && (hour < 8 || hour > 17)) {
    removeElements('#Header__green-dot')
  }

  runWithHCData(hcStorage)

  const agentInformation = await initHeader(hcStorage, chatInteractionId)

  await checkForRedirects(hcStorage, agentInformation)

  // creates a singleton instance on demand and,
  // initializes the SDK after the hcStorage is validated
  const smartlySDK = initSmartly(hcStorage)
  if (smartlySDK) {
    await smartlySDK.ready()
  }

  // exposes to global namespace just after
  // the ready() method has been resolved,
  // this way usage of `window.hc.smartlySDK`
  // and its methods will be safe.
  window.hc.smartlySDK = smartlySDK

  // Initialize Amplitude Experiment SDK
  // If this fails, we still want to show the page, so catch errors and just log them
  try {
    await experimentClient.init({
      domainUserId: tracker.getDomainUserId(),
      userProperties: {
        agent_id: agentInformation.agent_id
      }
    })
  } catch (e) {
    console.error('Error initializing Amplitude client: ', e)
  }

  updateAdaDisclaimer()

  toggleClass(document.body, 'stm-hc', isHcStmDomain())

  if (IS_ENROLL) {
    registerGeneralSpListeners()
  }

  pageView()

  return {
    hcStorage,
    smartlySDK,
    agentInformation,
    signedDetails
  }
}

export default initPage
