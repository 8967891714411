import { PIVOT_DOMAIN, SALES_DOMAIN } from 'core/constants/endpoints'

function getDomainIndex(): number {
  const paths = [SALES_DOMAIN, PIVOT_DOMAIN].map(domain => domain.replace('https://', ''))

  const { host } = window.location

  const index = paths.indexOf(host)
  return index === -1 ? 0 : index
}

export default getDomainIndex
