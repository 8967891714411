// @see https://docs.datadoghq.com/logs/log_collection/javascript/
import { datadogLogs, Logger } from '@datadog/browser-logs'
import getEnvironment from 'utils/getEnvironment'
import pkg from '../../../package.json'
import { DATADOG } from 'core/constants/thirdParties'
import { getServiceName } from './getServiceName'
import { PRODUCT } from 'core/constants/environment'

let logger: Logger = null
export default function getLogger(): Logger {
  if (!logger) {
    datadogLogs.init({
      clientToken: DATADOG.CLIENT_TOKEN,
      site: DATADOG.SITE_NAME,
      service: getServiceName(PRODUCT),
      env: getEnvironment(),
      version: pkg.version,
      forwardErrorsToLogs: false,
      telemetrySampleRate: 0,
      trackSessionAcrossSubdomains: true
    })
    logger = datadogLogs.logger
  }
  return logger
}
