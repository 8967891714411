import { setDOBToMember } from '@hc-frontend/business'
import { deepClone, stringToDate } from '@hc-frontend/utils'

function createStoreWithMiddleware() {
  let state: Partial<Pivot.State> = {}
  let globalListeners = []
  let middleware = arg => arg

  const withMiddleware = {
    update(newValues: Partial<Pivot.State>, onlySave = false) {
      const prevState = deepClone(state)
      const newState = { ...state, ...newValues }

      if (onlySave) {
        state = newState
      } else {
        state = middleware(newState)

        for (let index = 0; index < globalListeners.length; index++) {
          const result = globalListeners[index](state, prevState)
          if (result === false) {
            break
          }
        }
      }

      return state
    },

    registry(arg) {
      globalListeners = globalListeners.concat(arg)
    },

    setMiddleWare(fn) {
      middleware = fn
    },

    getState<StateShape extends Pivot.State>(): StateShape {
      const newState = deepClone(state) as StateShape

      // Business logic for date conversion for birthdays
      if (newState.hcStorage && newState.hcStorage.members) {
        newState.hcStorage.members = newState.hcStorage.members.map(member =>
          member.stringDOB
            ? setDOBToMember(member, stringToDate(member.stringDOB))
            : member
        )
      }

      return newState
    }
  }

  return withMiddleware
}

export default createStoreWithMiddleware
