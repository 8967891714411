function getSPCategory() {
  const { pathname = '' } = window.location

  const parts = pathname.split('/').filter(Boolean)

  if (parts.includes('product')) {
    return parts[parts.length - 1] ?? ''
  }

  return parts[0] ?? ''
}

export { getSPCategory }
