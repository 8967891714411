import sanitizeHtml from 'sanitize-html'

function applySanitize(value: string): string {
  return sanitizeHtml(value, {
    allowedTags: [],
    allowedAttributes: {}
  })
}

function sanitize<T>(value: T): T {
  if (value == null) {
    return value
  }
  if (value instanceof Array) {
    return value.map(sanitize) as T
  } else if (typeof value === 'object') {
    const objectKeys = Object.keys(value)
    for (const property of objectKeys) {
      value[property] = sanitize(value[property])
    }
    return value
  } else if (typeof value === 'string') {
    return applySanitize(value) as T
  } else {
    return value
  }
}

export default sanitize
