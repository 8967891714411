import querySelectorAll from 'utils/DOM/querySelectorAll'
import isString from 'utils/validation/isString'
import isArray from 'utils/validation/isArray'

function forEach(selector: StringOrNodeSelector, cb: (node: any) => any) {
  let type: any[]

  if (isArray(selector)) {
    type = selector
  } else if (selector instanceof NodeList) {
    type = [...selector]
  } else if (isString(selector)) {
    type = [...querySelectorAll(selector)]
  } else {
    type = [selector]
  }
  type.forEach(cb)
}

export default forEach
