import getAgent from 'core/services/getAgent'
import getPhone from 'core/services/getPhone'
import storage from 'proxy-storage'
import { fromPhoneE164, onlyDigits } from '@hc-frontend/utils'

export function getPhoneNumberPromise({
  hcStorage = storage.getItem('hc'),
  idLocation = ''
}) {
  const promises = [getPhone({ hcStorage, idLocation }), getAgent(hcStorage.agent_id)]

  return Promise.all(promises).then(([{ phone }, agentData]) => {
    if (!agentData || !agentData.data) {
      return { phone }
    }
    const agentInformation = agentData.data
    const promoNumber = agentInformation.phone
      ? `+1${onlyDigits(agentInformation.phone)}`
      : ''
    agentInformation.promo_number = promoNumber

    agentInformation.promo_number_formatted = promoNumber
      ? fromPhoneE164(agentInformation.promo_number)
      : ''

    return { phone, agentInformation }
  })
}

function getPhoneNumber(hcStorage) {
  return getPhoneNumberPromise(hcStorage)
}

export default getPhoneNumber
