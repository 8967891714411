import { RedirectStatus } from './types'

export function mergeStatus(
  currentStatus: RedirectStatus,
  operationResult: RedirectStatus | Promise<RedirectStatus>
): Promise<RedirectStatus> {
  if (operationResult instanceof Promise) {
    return operationResult.then(newStatus => ({
      ...currentStatus,
      ...newStatus
    }))
  } else {
    return Promise.resolve({ ...currentStatus, ...operationResult })
  }
}
