import urlParameter from '@hc-frontend/shared-assets/src/js/utils/url/urlParameter'
import isSalesCenterSubDomain from 'utils/pivot/isSalesCenterSubDomain'
import isAgentLogged from 'utils/pivot/agent/isAgentLogged'
import { IS_ENROLL } from 'core/constants/environment'
import buildLoginUrl from './utils/buildLoginUrl'
import type { RedirectStatus } from './utils'

export default function salesCenterLogin(): RedirectStatus {
  const isSalesCenter = isSalesCenterSubDomain()
  if (isSalesCenter || (!isSalesCenter && !IS_ENROLL)) {
    const auth = urlParameter('auth')
    if (auth === true && !isAgentLogged()) {
      return {
        redirectTo: buildLoginUrl()
      }
    }
  }

  return { continue: true }
}
