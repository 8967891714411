import store from 'utils/store/withMiddleware'
import { propAccessor } from '@hc-frontend/utils'

export default function getSalesChannel(): 'DTC' | 'ISC' | 'BROKERAGE' {
  const hcStorage = store.getState().hcStorage

  const channelName = propAccessor(hcStorage, 'channel.channelName', '').toLowerCase()

  switch (channelName) {
    case 'brokerage':
    case 'call center':
    case 'ecommerce':
      return 'BROKERAGE'
    case 'indy sales center':
      return 'ISC'
    default:
      return 'DTC'
  }
}
