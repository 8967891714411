import { fetchJSON } from '@hc-frontend/utils';

import type { ITransferRxPayload } from './post-transfer-rx-payload';
import type { ITransferRxResponse } from './post-transfer-rx-response';

/**
 * Post a call to request transfer Rx
 *
 * @param payload - The payload to send to endpoint
 * @returns - The response from endpoint
 *
 * @public
 *
 * @example
 *
 * ```ts
 * const payload = { ... }
 * const transferRx = await postTransferRxService(payload);
 * ```
 */
export async function postTransferRxService(
  payload: ITransferRxPayload
): Promise<ITransferRxResponse | null> {
  const { parsedBody } = await fetchJSON<ITransferRxResponse>(
    `https://${process.env['NEXT_PUBLIC_CUSTOMER_MGMT_SERVICE_DOMAIN']}/profile-mgmt/api/v1/prescription`,
    {
      body: payload,
    }
  );
  return parsedBody?.data ? parsedBody : null;
}
