import type { IDictionary } from '@hc-frontend/utils';

import type { AgentId } from './Agent';

export type SessionId = string;
export type UserId = string;
export type DataId = string;

export interface ITracking {
  src: string;
  agent_id: AgentId;
  subid?: string;
  aid?: string;
  pubid?: string;
  gclid?: string;
  msclkid?: string;
  irpid?: string;
  ircid?: string;
  iv?: string;
  pcid?: string;
  pr_pid?: string;
  utm_source?: string;
  utm_campaign?: string;
  utm_medium?: string;
  utm_content?: string;
  utm_term?: string;
}

export interface IHcSession {
  uid: SessionId;
  sid: UserId;
  email?: string;
  landing: string;
  tracking: ITracking;
}

export interface ISession extends IHcSession {
  dataId: DataId;
}

export enum Products {
  Health = 'HEALTH',
  Medicare = 'MEDICARE',
}

export interface ICreateSessionOptions {
  domain: string;
  landingPage: string;
  userId?: string;
  userAgent: string;
  appName: string;
  appVersion: string;
  platform: string;
  platformVersion: string;
  email?: string;
  tracking: IDictionary<string>;
  product: Products;
}

export interface IUpdateSessionOptions extends ICreateSessionOptions {
  sessionId: string;
}
