import standardizeMembers from 'utils/store/standardize/standardizeMembers'

function setMemberInterceptor(key, value) {
  if (key === 'hc' && value) {
    const { members } = value
    const newMembers = members.map(member => {
      if (member.stringDOB) {
        member.dob = member.stringDOB
      }
      return member
    })
    return { ...value, members: newMembers }
  }
}

function getMemberInterceptor(key, value) {
  if (key === 'hc' && value) {
    const { members } = value
    const newMembers = standardizeMembers(members)
    return { ...value, members: newMembers }
  }
  return value
}

export { getMemberInterceptor, setMemberInterceptor }
