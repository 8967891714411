import storage from 'proxy-storage'
import { sendOnCloseEvent } from 'core/services/email/blueshift/implementations/on-close'

export const popstateFunction = event => {
  if (event) {
    // Code to handle back button or prevent from navigation
    preventOnCloseEvent()
    history.back()
  }
}

export const beforeUnloadFunction = () => {
  const preventOnClose = storage.getItem('preventOnCloseEvent')
  if (!preventOnClose) {
    sendOnCloseEvent()
  }
  return null
}

export const onClose = () => {
  const onCloseDisabled = storage.getItem('preventOnCloseEvent')
  if (onCloseDisabled) {
    storage.removeItem('preventOnCloseEvent')
  }
  window.history.pushState({ page: 1 }, '', '')
  window.addEventListener('popstate', popstateFunction, false)
  window.addEventListener('beforeunload', beforeUnloadFunction)
}
export function preventOnCloseEvent() {
  storage.setItem('preventOnCloseEvent', 'true')
}
