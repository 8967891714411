import fetchJSONHandleError from 'utils/NET/fetchJSONHandleError'
import { SESSION } from 'core/constants/endpoints'
import mergeData from './mergeData'

function getApplicantContext(userId, hcStorage, updateLocation) {
  return fetchJSONHandleError({
    url: `${SESSION}/applicantContext/${userId}.json`
  })
    .then(mergeData(hcStorage, updateLocation))
    .catch(() => {
      return hcStorage
    })
}

export default getApplicantContext
