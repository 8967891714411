import { fetchJSON } from '@hc-frontend/utils';

import type { IResetPasswordPayload } from './post-reset-password-service-payload';
import type { IResetPasswordResponse } from './post-reset-password-service-response';

/**
 * Post a call to reset password
 *
 * @param payload - The payload to send to okta reset password endpoint
 * @returns - The response from okta reset password endpoint
 *
 * @public
 *
 * @example
 *
 * ```ts
 * const payload = { ... }
 * const resetPwd = await postResetPasswordService(payload);
 * ```
 */
export async function postResetPasswordService(
  payload: IResetPasswordPayload
): Promise<IResetPasswordResponse | null> {
  const { parsedBody } = await fetchJSON<IResetPasswordResponse>(
    `https://${process.env['NEXT_PUBLIC_OKTA_DOMAIN']}/api/v1/authn/recovery/password`,
    {
      body: payload,
    }
  );
  return parsedBody?.data ? parsedBody : null;
}
