import isSalesCenterSubDomain from 'utils/pivot/isSalesCenterSubDomain'
import getDTCPort from 'utils/pivot/environment/getDTCPort'
import {
  IS_AGENT,
  IS_DENTAL_AND_VISION,
  IS_FIXED_BENEFIT_MEDICAL
} from 'core/constants/environment'
import getEnvironment from 'utils/getEnvironment'
import {
  type RedirectStatus,
  getAgentCharacteristics,
  convertPathname,
  buildURL
} from './utils'

function getEnvironmentValues(agent, isSalesCenterAgency) {
  const env = getEnvironment()
  const environment = env === 'prd' ? '' : `${env}.`
  const { pathname, protocol, port, search } = window.location
  const { isDTCAgent, isPivotAgent, isSalesAgent } = getAgentCharacteristics(
    agent,
    isSalesCenterAgency
  )

  const noRedirectApp = IS_DENTAL_AND_VISION || IS_FIXED_BENEFIT_MEDICAL
  const newPathname = convertPathname(pathname, isDTCAgent)

  return {
    environment,
    isSalesAgent,
    newPathname,
    isDTCAgent,
    noRedirectApp,
    isPivotAgent,
    protocol,
    port,
    search
  }
}

export default function organicUser({
  agent,
  isSalesCenterAgency
}: RedirectStatus): RedirectStatus {
  if (isSalesCenterSubDomain()) {
    const {
      isSalesAgent,
      newPathname,
      isDTCAgent,
      noRedirectApp,
      environment,
      isPivotAgent,
      port,
      search,
      protocol
    } = getEnvironmentValues(agent, isSalesCenterAgency)

    if (!IS_AGENT && (isSalesCenterAgency || isSalesAgent)) {
      return { organicRedirect: true }
    }

    if (
      newPathname === '' ||
      isSalesCenterAgency ||
      isSalesAgent ||
      (isDTCAgent && noRedirectApp)
    ) {
      return { continue: false }
    }

    const subdomain = isPivotAgent ? environment || 'www.' : `enroll.${environment}`
    const newPort = isDTCAgent ? getDTCPort() : port

    return {
      redirectTo: buildURL({
        protocol,
        subdomain,
        port: newPort,
        pathname: newPathname,
        search
      })
    }
  }

  return { continue: true }
}
