import { getGenericBlueshiftParams } from 'core/services/email/blueshift/implementations/generic'
import { sendBlueshiftEvent, BlueshiftEvent } from 'core/services/email/blueshift'

export function sendLandingViewEvent(): Promise<void> {
  const dataPayload = {
    ...getGenericBlueshiftParams()
  }
  return sendBlueshiftEvent({
    dataPayload,
    eventName: BlueshiftEvent.LANDING,
    sanitize: true
  })
}
