import { BLUESHIFT_DOMAIN } from 'core/constants/endpoints'
import fetchJSONHandleError from 'utils/NET/fetchJSONHandleError'
import { filterDictionary } from '@hc-frontend/utils'
import { BlueshiftMessageArgs, BlueshiftMessageShape } from 'Blueshift'
import { IS_FIXED_BENEFIT_MEDICAL } from 'core/constants/environment'

export enum BlueshiftEvent {
  LANDING = 'landing',
  QUOTES_VIEW = 'ph_l_quote',
  ISC_QUOTES_VIEW = 'ph_l_isc_quote',
  EMAIL_QUOTE = 'ph_l_save_quote',
  ISC_EMAIL_QUOTE = 'ph_l_isc_save_quote',
  ELIGIBILITY_VIEW = 'ph_l_eligibility',
  ISC_ELIGIBILITY_VIEW = 'ph_l_isc_eligibility',
  ADDONS_VIEW = 'ph_l_add_ons',
  ISC_ADDONS_VIEW = 'ph_l_isc_add_ons',
  CHECKOUT_STEP_ONE = 'check-out-step-one',
  ISC_TYP_VIEW = 'ph_l_isc_thank_you_page',
  TYP_VIEW = 'ph_l_thank_you_page',
  ON_CLOSE = 'ph_l_on_close',
  ISC_ON_CLOSE = 'ph_l_isc_on_close',
  ON_REFRESH = 'ph_l_on_refresh',
  ISC_ON_REFRESH = 'ph_l_isc_on_refresh'
}

export const sendBlueshiftEvent = async (args: BlueshiftMessageArgs) => {
  if (!IS_FIXED_BENEFIT_MEDICAL) {
    const finalPayload = buildMessage(args)
    return fetchJSONHandleError({
      url: `${BLUESHIFT_DOMAIN}/api/v1/blueshift-event`,
      data: finalPayload
    })
  }
}

const buildMessage = ({
  eventName,
  dataPayload,
  sanitize = false
}: BlueshiftMessageArgs): BlueshiftMessageShape => {
  const finalPayload = sanitize
    ? filterDictionary(
        (value: string) => !['', null, undefined].includes(value),
        dataPayload
      )
    : dataPayload
  return {
    event_name: eventName,
    payload: {
      ...finalPayload,
      on: new Date().toISOString()
    }
  }
}
