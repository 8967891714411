import getValueFromCookies from 'utils/pivot/cookie/getValueFromCookies'

function getAgentLoggedId() {
  const cookieVal = 'ph_af=true-'
  const REGEX = /^ph_af=/

  return getValueFromCookies(cookieVal, REGEX)
}

export default getAgentLoggedId
