import { fetchJSON } from '@hc-frontend/utils';

import type { ICallAccountPayload } from './fetch-call-account-service-payload';
import type { ICallAccountResponse } from './fetch-call-account-service-response';

/**
 * Fetch a call for account signup.
 *
 * @param payload - The payload to send to companion backend endpoint
 * @returns - The response from companion backend endpoint
 *
 * @public
 *
 * @example
 *
 * ```ts
 * const payload = { ... }
 * ```
 */
export async function postCallAccountService(
  payload: ICallAccountPayload
): Promise<ICallAccountResponse | Error> {
  const { parsedBody } = await fetchJSON<ICallAccountResponse>(
    `https://${process.env['NEXT_PUBLIC_IDENTITY_SERVICE_DOMAIN']}/identity/api/v1/users`,
    {
      body: payload,
    }
  );
  return parsedBody?.data ? parsedBody : new Error();
}
