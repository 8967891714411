import { getPivotBlueshiftParams } from 'core/services/email/blueshift/implementations/generic'
import { STM_ELIGIBLE } from 'core/constants/eligibility'
import { findSpouse } from '@hc-frontend/business'
import storage from 'proxy-storage'
import { ISC } from 'core/constants/channels'
import { sendBlueshiftEvent, BlueshiftEvent } from 'core/services/email/blueshift'

function getEventName(oldSessionId: string, sales_channel: string) {
  if (oldSessionId) {
    if (sales_channel == ISC) return BlueshiftEvent.ISC_ON_REFRESH
    else return BlueshiftEvent.ON_REFRESH
  } else {
    if (sales_channel == ISC) return BlueshiftEvent.ISC_ON_CLOSE
    else return BlueshiftEvent.ON_CLOSE
  }
}

export function sendOnCloseEvent(oldSessionId: string): Promise<void> {
  const hcStorage = storage.getItem('hc')

  const spouse = findSpouse(hcStorage.members)

  const dataPayload = {
    ...getPivotBlueshiftParams(),
    placement: hcStorage.placement,
    eligibility:
      hcStorage.leadStatus != null ? hcStorage.leadStatus == STM_ELIGIBLE : 'unknown',
    want_dental_and_vision:
      hcStorage.want_dental_and_vision != null
        ? hcStorage.want_dental_and_vision
        : 'unknown',
    want_supplemental:
      hcStorage.want_supplemental != null ? hcStorage.want_supplemental : 'unknown',
    first_name: hcStorage.contact.first_name,
    last_name: hcStorage.contact.last_name,
    email_address: hcStorage.contact.email_address,
    address: hcStorage.contact.address,
    phone_number: hcStorage.contact.phone_home,
    spouse_first_name: spouse != null ? spouse.firstName : '',
    spouse_last_name: spouse != null ? spouse.lastName : ''
  }

  if (oldSessionId) dataPayload.oldSessionId = oldSessionId

  if (dataPayload.sales_channel === ISC) {
    dataPayload.agent_id = hcStorage.agent_id
  }

  return sendBlueshiftEvent({
    dataPayload,
    eventName: getEventName(oldSessionId, dataPayload.sales_channel),
    sanitize: true
  })
}
