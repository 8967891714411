import store from 'utils/store/withMiddleware'

const fromMember = (member, suffix) => {
  const parseMap = {
    fname: 'firstName',
    lname: 'lastName',
    email: 'email',
    phone_number: 'phone',
    gender: 'gender',
    dob: 'stringDOB'
  }

  return Object.entries(parseMap).reduce((acc, [key, value]) => {
    const memberKey = `${key}${suffix}`
    if (member[value]) {
      acc[memberKey] = member[value]
    }
    return acc
  }, {})
}

const parseUser = hcStorage => {
  const members = hcStorage.members
  if (!members || !members.length) {
    return undefined
  }

  const primaryMember = members.find(m => m.id === 'primary')
  if (!primaryMember) {
    return undefined
  }
  let user = fromMember(primaryMember, '')

  const spouse = members.find(m => m.id === 'spouse')
  if (spouse)
    user = {
      ...user,
      ...fromMember(spouse, '_s')
    }

  return user
}

const parseQuote = state => {
  const location = state.hcStorage.location
  if (!location) {
    return undefined
  }

  const quote = {
    ...location
  }

  ;['planId', 'policies', 'sDate', 'endDate'].forEach(key => {
    if (state[key]) {
      quote[key.toLowerCase()] = state[key]
    }
  })

  return quote
}

export default function readFromState(): Pivot.Session | undefined {
  const state = store.getState()
  const { hcStorage, enrollment } = state
  const session = hcStorage.session || {}

  const user = parseUser(hcStorage)
  if (user) {
    session.user = user
  }

  const quote = parseQuote(state)
  if (quote) {
    session.quote = quote
  }

  if (hcStorage.utm) {
    session.browser = hcStorage.utm
  }

  if (enrollment) {
    session.cart = enrollment
  }

  return session
}
