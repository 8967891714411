export function convertPathname(pathname, toEnroll = true) {
  if (toEnroll) {
    const isBTM = pathname.includes('/bridge-to-medicare')

    const newPathname = pathname.replace(
      `/product/${isBTM ? 'bridge-to-medicare' : 'short-term-health-insurance'}`,
      ''
    )
    return newPathname
  }

  if (pathname.includes('/product')) {
    return pathname
  }

  const noRedirectPaths = ['welcome-back', 'welcome-back-quotes']

  const noRedirectPath = noRedirectPaths.find(path => pathname.includes(path))

  if (noRedirectPath) return null

  const differentPaths = [
    { value: 'add-ons', newValue: 'start-application' },
    { value: 'census/dependents', newValue: 'census' }
  ]

  const differentPath = differentPaths.find(path => pathname.includes(path.value))

  const newPath = differentPath
    ? pathname.replace(differentPath.value, differentPath.newValue)
    : pathname

  return newPath.includes('/product')
    ? newPath
    : `/product/short-term-health-insurance${newPath}`
}
