import defaultStorage from '@hc-frontend/shared-assets/src/js/sem/storage-manager/defaultStorage'
import loadData from '@hc-frontend/shared-assets/src/js/sem/storage-manager/loadData'
import getApplicantContextBySid from 'core/services/getApplicantContextBySessionId'
import urlParameter from '@hc-frontend/shared-assets/src/js/utils/url/urlParameter'
import getApplicantContext from 'core/services/getApplicantContext'
import getAgentLoggedId from 'utils/pivot/agent/getAgentLoggedId'
import updateByQuoteKey from 'core/initPage/updateByQuoteKey'
import setStartDate from 'core/controllers/setStartDate'
import sanitize from 'utils/DOM/sanitize'
import storage from 'proxy-storage'
import { deepClone } from '@hc-frontend/utils'
import getValidEndDate from 'core/controllers/getEndDate'
import { findChildren } from '@hc-frontend/business'
import { MemberType } from '@hc-frontend/entities'
import standardizeMembers from 'utils/store/standardize/standardizeMembers'

function getMembersWithNamesUpdated(originalMembers, hcStorage) {
  const standardOriginalMembers = standardizeMembers(originalMembers)
  const children = findChildren(standardOriginalMembers)

  return standardizeMembers(hcStorage.members).map((member, index) => {
    const nameOwner =
      member.type.toLowerCase() !== MemberType.Child
        ? standardOriginalMembers.find(({ type }) => type === member.type)
        : children.shift()

    return {
      ...hcStorage.members[index],
      firstName: nameOwner?.firstName || '',
      lastName: nameOwner?.lastName || ''
    }
  })
}

async function getContext({
  clearStorage,
  updateLocation
}: {
  clearStorage?: boolean
  updateLocation?: boolean
}): Promise<Pivot.Store> {
  const uid = urlParameter('uid')
  const sid = urlParameter('sid')
  const quoteKey = urlParameter('quoteKey')
  const gclid = urlParameter('gclid')
  const agentCloudOpportunityId = urlParameter('agentcloudOpportunityId')

  let hcStorage = storage.getItem('hc') as Pivot.Store

  if (hcStorage && clearStorage) {
    hcStorage = {
      ...deepClone(defaultStorage),
      invoca_phone: hcStorage.invoca_phone,
      utm: hcStorage.utm,
      leadid: hcStorage.leadid,
      agentInfo: hcStorage.agentInfo,
      agent_id: hcStorage.agent_id,
      aid: hcStorage.aid,
      pubid: hcStorage.pubid,
      subid: hcStorage.subid,
      gclid: hcStorage.gclid,
      msclkid: hcStorage.msclkid,
      irpid: hcStorage.irpid,
      ircid: hcStorage.ircid,
      bt_sid: hcStorage.bt_sid,
      source: hcStorage.source,
      pixel: hcStorage.pixel,
      url_version: hcStorage.url_version,
      landing_url: hcStorage.landing_url,
      picreel_time: hcStorage.picreel_time,
      healthcareSession: hcStorage.healthcareSession,
      healthcareUserId: hcStorage.healthcareUserId
    }

    storage.setItem('hc', hcStorage)
  }

  const email = hcStorage ? hcStorage.contact.email_address : undefined
  hcStorage = await loadData(email)
  const originalMembers = hcStorage.members

  hcStorage =
    (quoteKey && (await updateByQuoteKey(hcStorage, quoteKey))) ||
    (uid && (await getApplicantContext(uid, hcStorage, updateLocation))) ||
    (sid && (await getApplicantContextBySid(sid, hcStorage, updateLocation))) ||
    hcStorage

  if (!quoteKey) {
    hcStorage.agent_id = getAgentLoggedId() || hcStorage.agent_id || 2010000

    const newStartDate = setStartDate(hcStorage.sdate)
    const endDate = urlParameter('endDate') || hcStorage.endDate || ''
    hcStorage.endDate = getValidEndDate(hcStorage.sdate, newStartDate, endDate)

    hcStorage.originalSDate = hcStorage.sdate
    hcStorage.sdate = newStartDate
  }

  // Sometimes agent_id comes with a white space from the URL. Eg. '%2031785' instead of '31785'
  if (typeof hcStorage.agent_id === 'string') {
    hcStorage.agent_id = hcStorage.agent_id.trim()
  }

  if (quoteKey) {
    hcStorage.members = getMembersWithNamesUpdated(originalMembers, hcStorage)
  }

  if (agentCloudOpportunityId) {
    hcStorage.agentCloudOpportunityId = agentCloudOpportunityId
  }

  if (gclid && window.location.pathname.includes('aflac')) {
    const utm = { ...hcStorage.utm, utm_term: gclid }

    storage.setItem('hc', { ...hcStorage, utm })
  }

  return sanitize(hcStorage)
}

export default getContext
