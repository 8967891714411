import { type RedirectStatus, getAgentCharacteristics } from './utils'

export default function channelName({
  agent,
  isSalesCenterAgency
}: RedirectStatus): RedirectStatus {
  const stopChannels = ['affiliates', 'ecommerce']
  const { channelName } = getAgentCharacteristics(agent, isSalesCenterAgency)
  return {
    continue: !stopChannels.includes(channelName)
  }
}
