// cspell:disable
import auditLead from '@hc-frontend/shared-assets/src/exit-messaging/experiences/modal-connectme-input/auditLead'
import { CONNECTME_STATUS_MAP, CONNECTME } from 'core/constants/thirdParties'
import initCustomHolder from './initCustomHolder'
import store from 'utils/store/withMiddleware'
import updateStatus from './updateStatus'
import references from './references'
import { onlyDigits } from '@hc-frontend/utils'

function initCall({ placement, invocaPhone, phoneNumber }) {
  const { hcStorage } = store.getState()
  const holder = references[placement].holder

  window.__g3cm.phonenumber = onlyDigits(phoneNumber)
  window.__g3cm.destination = invocaPhone.promo_number
  window.__g3cm.conversionid = hcStorage.healthcareSession

  const destination = invocaPhone.promo_number
  const conversionid = hcStorage.healthcareSession

  const __initjsonp = Object.assign(window.__g3cm.initjsonp)
  const __calljsonp = Object.assign(window.__g3cm.calljsonp)
  const __statusjsonp = Object.assign(window.__g3cm.statusjsonp)

  window.__g3cm.initjsonp = args => {
    // invoke(onInit)()
    updateStatus(placement, CONNECTME.init)
    __initjsonp.call(window.__g3cm, args)
  }

  window.__g3cm.calljsonp = args => {
    // invoke(onCall)()
    updateStatus(placement, CONNECTME.init)
    __calljsonp.call(window.__g3cm, args)
  }

  window.__g3cm.statusjsonp = args => {
    // invoke(onStatus)(args.callstatus)
    updateStatus(placement, CONNECTME_STATUS_MAP[args.callstatus] || CONNECTME.init)
    __statusjsonp.call(window.__g3cm, args)
  }

  initCustomHolder.call(window.__g3cm, { holder, destination, conversionid })
  auditLead()
}

export default initCall
