import { setDOBToMember } from '@hc-frontend/business'
import { stringToDate } from '@hc-frontend/utils'

const assertDate = (dateSource: string | Date) => {
  if (dateSource instanceof Date) {
    return dateSource
  }

  const isoDateRegex =
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2})?$/
  if (isoDateRegex.test(dateSource)) {
    return new Date(dateSource)
  }

  const dateStringRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/
  if (dateStringRegex.test(dateSource)) {
    const dateFromString = stringToDate(dateSource)
    if (dateFromString) return dateFromString
  }

  throw new Error('Invalid date format')
}

function convertToMember(members: IMember[]): IMember[] {
  return members.map(member => {
    member.type = member.type.toLowerCase() as MemberType

    if (member.dob) {
      member = setDOBToMember(member, assertDate(member.dob))
    }
    return member
  })
}

export { convertToMember }
