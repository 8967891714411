export const DENTAL = 4
export const VISION = 5
export const LATITUDE = 12
export const SUPPLEMENTAL_BENEFIT = 18

// stm products
export const ECONOMY = 6
export const QUANTUM = 11
export const DELUXE = 14
export const STANDARD = 13
export const CORE = 15
export const CHOICE = 16
export const EPIC = 17
export const BTM = 8
export const FIXED = 7
export const EPIC_BASE = 20
export const EPIC_PPO = 21
export const AMERITAS_DENTAL = 22
export const AMERITAS_VISION = 23
export const SURE_CARE = 24

// Dental flow
export const DENTAL_STAND_ALONE = 19
export const VISION_ID = 14

export const ECONOMY_NAME = 'economy'
export const QUANTUM_NAME = 'quantum'
export const DELUXE_NAME = 'deluxe'
export const STANDARD_NAME = 'standard'
export const CORE_NAME = 'core'
export const CHOICE_NAME = 'choice'
export const EPIC_NAME = 'epic'
export const DENTAL_STAND_ALONE_NAME = 'dental'
export const STM_NAME = 'Short Term Medical'
export const BTM_NAME = 'Bridge to Medicare'
export const BTM_NAME_TM = `${BTM_NAME}™`
export const FBM_NAME = 'Fixed Benefit Medical'
export const EPIC_BASE_NAME = 'epic_base'
export const EPIC_PPO_NAME = 'epic_ppo'
export const DENTAL_NAME = 'dental'
export const VISION_NAME = 'vision'
export const LATITUDE_NAME = 'latitude'
export const SURE_CARE_NAME = 'sure_care'

export const STM_KEY = 'stm'
export const BTM_KEY = 'btm'
export const FBM_KEY = 'fbm'

export const STM_DURATION_BASED_PRODUCTS = [
  ECONOMY,
  QUANTUM,
  DELUXE,
  STANDARD,
  CORE,
  CHOICE,
  EPIC,
  SURE_CARE
]
export const STM_DURATION_BASED_PRODUCT_NAMES = [
  ECONOMY_NAME,
  QUANTUM_NAME,
  DELUXE_NAME,
  STANDARD_NAME,
  CORE_NAME,
  CHOICE_NAME,
  EPIC_NAME,
  SURE_CARE_NAME
]

export const ADD_ONS_PLANS = [
  DENTAL,
  VISION,
  LATITUDE,
  SUPPLEMENTAL_BENEFIT
  // AMERITAS_DENTAL,
  // AMERITAS_VISION
]
export const NOT_STM_PLANS = [...ADD_ONS_PLANS, BTM, FIXED]

/**
 * These are products that can be directly added to a plan without requiring another add-on to be added first.
 * For example, you can add an Ameritas Dental plan without an Ameritas Vision plan or vice versa, but you can't
 * add a Renaissance Vision plan without a Renaissance Dental plan.
 */
export const PRIMARY_DNV_ADD_ONS = [
  DENTAL
  // AMERITAS_DENTAL,
  // AMERITAS_VISION
]

/** Dental and vision product types that can be sold as stand-alone products. */
export const DNV_STAND_ALONE = [
  DENTAL_STAND_ALONE
  // AMERITAS_DENTAL,
  // AMERITAS_VISION
]

export const ALL_DENTAL = [
  DENTAL,
  // AMERITAS_DENTAL,
  DENTAL_STAND_ALONE
]
export const ALL_VISION = [
  VISION
  // AMERITAS_VISION
]
export const RENAISSANCE_PRODUCTS = [DENTAL, VISION, DENTAL_STAND_ALONE]
