import forEach from 'utils/DOM/forEach'

function addEventListener(
  selector: StringOrNodeSelector,
  event: string,
  callback: (e: Event) => void
) {
  forEach(selector, node => node.addEventListener(event, callback))
}

export default addEventListener
