import sanitize from '../../DOM/sanitize'

export const propertiesToSanitize = [
  'contact',
  'utm',
  'location',
  'members',
  'sdate',
  'endDate',
  'landing_url'
]

export const storeSanitizeInterceptor = (key: string, value: any) => {
  if (key === 'hc' && value) {
    propertiesToSanitize.forEach(prop => (value[prop] = sanitize(value[prop])))
    return value
  }
}
