function getValueFromCookies(cookieVal: string, searchRegex: RegExp): string {
  const { cookie } = document

  if (cookie && cookie !== '') {
    const cookieArr = cookie.split('; ').find(value => searchRegex.test(value))
    if (cookieArr && cookieArr.includes(cookieVal)) {
      return cookieArr.replace(`${cookieVal}`, '')
    }
  }
  return undefined
}

export default getValueFromCookies
