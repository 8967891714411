import type { PromNull } from '@hc-frontend/utils';
import { fetchJSON, isArray } from '@hc-frontend/utils';

import { geoLocationURI } from '../config';
import type { IFetchAllLocationsByZipServiceResponse } from './fetch-all-locations-by-zip-service-response';

/**
 * Fetch all possible locations that match the `zip` code
 *
 * @param zip - The zip code to be fetched
 * @returns The array of possible locations related with the zip, otherwise an empty array will be returned
 *
 * @public
 *
 * @example
 * ```ts
 * const locations = await fetchAllLocationsByZipService('3133');
 * ```
 */
async function fetchAllLocationsByZipService(
  zip: string
): PromNull<IFetchAllLocationsByZipServiceResponse[]> {
  const url = `${geoLocationURI()}/GeoLocation/${zip}`;
  const { parsedBody } = await fetchJSON<
    Array<IFetchAllLocationsByZipServiceResponse>
  >(url);

  return isArray(parsedBody) ? parsedBody : null;
}

export { fetchAllLocationsByZipService };
