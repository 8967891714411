import {
  onCLS,
  onFCP,
  onLCP,
  onTTFB,
  onFID,
  ReportCallback,
  ReportOpts
} from 'web-vitals'
type WebVitalsFunction = (onReport: ReportCallback, opts?: ReportOpts) => void

/**
 * It takes a report callback and an array of web vitals functions and then calls each web vitals function with
 * the report callback
 * @param ReportCallback - A function that will be called with the metric name and value.
 * @param WebVitalsFunction[] - An array of functions
 */
export function trackingWebVitals(
  reportCallback: ReportCallback,
  webVitalsFunctions: WebVitalsFunction[] = []
) {
  ;[onFCP, onLCP, onCLS, onTTFB, onFID, ...webVitalsFunctions].forEach(webVitalsFun =>
    webVitalsFun(reportCallback)
  )
}
