import warn from '@hc-frontend/shared-assets/src/js/utils/warnings/warn'
import sessionCachePromise from 'core/sessionCachePromise'
import { BACKEND_PIVOT } from 'core/constants/endpoints'
import {
  fetchAgentInformationService,
  IFetchAgentInformationServiceResponse
} from '@hc-frontend/services'

let promise = null

function getAgent(agentId): IFetchAgentInformationServiceResponse | undefined {
  if (promise) {
    return promise
  }

  promise = fetchAgentInformationService(agentId).then(resp => {
    if (!resp) {
      warn(`No data has been found for agent: ${agentId}`, {
        tags: ['severity:mid'],
        url: `${BACKEND_PIVOT}/agent/${agentId}`
      })
      return undefined
    } else {
      if (resp.data) {
        resp.data.full_name = `${resp.data.first_name || ''} ${resp.data.last_name || ''}`
      }

      promise = null
    }
    return resp
  })

  return promise
}

export default sessionCachePromise(getAgent, 'AGENT_INFO')
