/* eslint-disable complexity */
import formatDate from '@hc-frontend/shared-assets/src/js/utils/dates/formatDate'
import storage, { WebStorage } from 'proxy-storage'
import isArray from 'utils/validation/isArray'

const sessionStorage = new WebStorage('sessionStorage')

function transformGender(gender) {
  return gender.toLowerCase()[0] === 'f' ? 'f' : 'm'
}

function transformTobacco(tobacco) {
  return (tobacco || '').toLowerCase()[0] === 'y' ? 'y' : 'n'
}

function maskPhoneNumber(phone = '') {
  if (!phone) {
    return ''
  }

  if (typeof phone === 'number') {
    phone = '' + phone
  }

  return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
}

function mergeData(hcStorage, updateLocation = false, returnRawResponse = false) {
  return response => {
    if (response) {
      const { ApplicantContext, Geolocation } = response
      const { Holder, Couple, Children } = ApplicantContext
      const formatDateFn =
        Holder.DateOfBirth.split('/')[0].length === 4
          ? formatDate // If true assumes that all DOB comes in yyyy/MM/dd format
          : dob => dob

      if (!hcStorage.members[0].dob) {
        hcStorage.members = [
          {
            ...hcStorage.members[0],
            dob: formatDateFn(Holder.DateOfBirth),
            gender: transformGender(Holder.Gender)
          }
        ]

        if (Couple && Couple.DateOfBirth) {
          hcStorage.members = hcStorage.members.concat([
            {
              id: 'spouse',
              type: 'Spouse',
              dob: formatDateFn(Couple.DateOfBirth),
              gender: transformGender(Couple.Gender),
              firstName: Couple.FirstName || '',
              lastName: Couple.LastName || '',
              tobacco: transformTobacco(Couple.TobaccoUse)
            }
          ])
        }

        if (Children && Children.length) {
          hcStorage.members = hcStorage.members.concat(
            Children.map((child, index) => ({
              id: `child_${index + 1}`,
              type: 'Child',
              dob: formatDateFn(child.DateOfBirth),
              gender: transformGender(child.Gender),
              firstName: child.FirstName || '',
              lastName: child.LastName || '',
              tobacco: transformTobacco(child.TobaccoUse)
            }))
          )
        }
      }

      hcStorage.household = {
        ...hcStorage.household,
        income: ApplicantContext.HouseIncome && +ApplicantContext.HouseIncome,
        fpl: ApplicantContext.FederalPovertyLevel || ''
      }
      hcStorage.life_event = ApplicantContext.Qle && +ApplicantContext.Qle

      hcStorage.members[0].firstName =
        hcStorage.members[0].firstName || Holder.FirstName || ''
      hcStorage.members[0].lastName =
        hcStorage.members[0].lastName || Holder.LastName || ''

      hcStorage.contact = {
        email_address:
          hcStorage.contact.email_address || ApplicantContext.ApplicantEmail || '',
        address: hcStorage.contact.address || ApplicantContext.Address || '',
        phone_home:
          hcStorage.contact.phone_home || maskPhoneNumber(ApplicantContext.PhoneNumber),
        is_mobile: hcStorage.contact.is_mobile || '',
        first_name: hcStorage.members[0].firstName,
        last_name: hcStorage.members[0].lastName
      }

      if (
        (updateLocation || !hcStorage.location || !hcStorage.location.zip_code) &&
        isArray(Geolocation) &&
        Geolocation[0].ZipCode &&
        Geolocation[0].StateAcronym
      ) {
        hcStorage.location = {
          zip_code: Geolocation[0].ZipCode,
          state_acronym: Geolocation[0].StateAcronym,
          state: Geolocation[0].StateName,
          county: Geolocation[0].CountyName,
          city: Geolocation[0].City
        }
      }

      if (!hcStorage.pivotPlan && ApplicantContext.LastPivotPlanVisited?.PlanId) {
        hcStorage.pivotPlan = {
          planId: parseInt(ApplicantContext.LastPivotPlanVisited.PlanId)
        }
      }

      if (!hcStorage.period && ApplicantContext.LastPivotPlanVisited?.Period) {
        hcStorage.period = parseInt(ApplicantContext.LastPivotPlanVisited.Period)
      }

      if (!hcStorage.policies && ApplicantContext.LastPivotPlanVisited?.Policies) {
        hcStorage.policies = parseInt(ApplicantContext.LastPivotPlanVisited.Policies)
      }

      storage.setItem('hc', hcStorage)
      sessionStorage.setItem('context', true)
    }

    return returnRawResponse ? { hcStorage, rawResponse: response } : hcStorage
  }
}

export default mergeData
