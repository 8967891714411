export const CONNECTME = {
  starting: 'starting',
  init: 'init',
  ringing: 'ringing',
  connected: 'connected',
  completed: 'completed',
  failed: 'failed',
  unavailable: 'unavailable'
}

export const CONNECTME_STATUS_MAP = {
  initiated: CONNECTME.ringing,
  ringing: CONNECTME.ringing,
  queued: CONNECTME.init,
  ['in-progress']: CONNECTME.connected,
  connected: CONNECTME.connected,
  completed: CONNECTME.completed,
  failed: CONNECTME.failed,
  default: CONNECTME.unavailable
}

export const CONNECTME_MESSAGES = {
  [CONNECTME.starting]: 'Your phone will ring in 5 seconds...',
  [CONNECTME.init]: 'Your phone will ring in 5 seconds...',
  [CONNECTME.ringing]: "Check your phone, it's ringing...",
  [CONNECTME.connected]: 'Check your phone, your call has been connected',
  [CONNECTME.completed]: 'Thank you',
  [CONNECTME.failed]: 'No agents are available at this time, please try again later',
  [CONNECTME.unavailable]: 'Something went wrong, please try again later'
}

export const CONNECTME_ENABLED_CLASS = 'connectme-enabled'
export const CONNECTME_STATUS_CLASS = 'connectme-status'

export enum DATADOG {
  CLIENT_TOKEN = 'pub66344d72854e3b226875de38c4504c3d',
  SITE_NAME = 'datadoghq.com', // cspell:disable-line
  SERVICE_NAME_SUFFIX = `.pivothealth`
}
