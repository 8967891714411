import agentStore from 'utils/store/simple'
import store from 'utils/store/withMiddleware'

function updateStorages(response) {
  agentStore.update(response.agentInformation)

  return store.update({
    hcStorage: response.hcStorage,
    signedDetails: response.signedDetails
  })
}

export default updateStorages
