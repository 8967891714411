import fetchJSONHandleError from 'utils/NET/fetchJSONHandleError'
import { SEM_CONTENT } from 'core/constants/endpoints'
import { IS_ENROLL, IS_LOCAL_ENVIRONMENT } from 'core/constants/environment'
import { makePayload } from './makePayload'
import { MapResponse, mapResponse } from './mapResponse'
import getSnowplowIdCookie from '@hc-frontend/shared-assets/src/js/utils/dom/cookies/getSnowPlowIdCookie'

type GetPhoneOptions = {
  hcStorage: Pivot.Store
  idLocation?: string
  ringPoolId?: string
  retryCallback?: ({
    hcStorage,
    idLocation,
    ringPoolId
  }: GetPhoneOptions) => Promise<MapResponse>
}

export const DEFAULT_PHONES = {
  enroll: {
    promo_number: '+16515715136',
    promo_number_formatted: '(651) 571-5136',
    promo_number_formatted_dash: '651-571-5136'
  },
  organic: {
    promo_number: '+18772460106',
    promo_number_formatted: '(877) 246-0106',
    promo_number_formatted_dash: '877-246-0106'
  }
}

let retry = 0
const RETRY_LIMIT = 2
const time = !IS_LOCAL_ENVIRONMENT ? 800 : 0

async function retryFn({ hcStorage, idLocation, ringPoolId }: GetPhoneOptions) {
  await new Promise(resolve => setTimeout(resolve, time))
  retry++
  return retry < RETRY_LIMIT
    ? getPhone({ hcStorage, idLocation, ringPoolId })
    : Promise.resolve({ phone: DEFAULT_PHONES[ringPoolId] })
}

async function getPhone({
  hcStorage,
  idLocation = '',
  ringPoolId = `${IS_ENROLL ? 'enroll' : 'organic'}`,
  retryCallback = retryFn
}: GetPhoneOptions): Promise<MapResponse> {
  const payload = makePayload(hcStorage, ringPoolId, idLocation)
  const spCookieValue = getSnowplowIdCookie()
  if (spCookieValue) {
    return fetchJSONHandleError({
      url: `${SEM_CONTENT}/call/campaign`,
      data: payload,
      config: {
        headers: {
          'snowplow-data': spCookieValue
        }
      }
    }).then(r => mapResponse(r, ringPoolId))
  } else {
    return await retryCallback({ hcStorage, idLocation, ringPoolId })
  }
}

export default getPhone
