import getById from 'utils/DOM/getById'
import toggleClass from 'utils/DOM/toggleClass'

function setValue(value, id) {
  if (value) {
    const node = getById(id)

    if (node) {
      node.innerHTML = value
    }
  }
}

function updateContent(content) {
  if ('h1' in content.data) {
    setValue(content.data.h1, 'content-title')
    setValue(content.data.h2, 'content-subtitle')
    setValue(content.data.h3, 'content-cta')
    setValue(content.data.field_1, 'content-field-1')
    setValue(content.data.field_2, 'content-field-2')
    setValue(content.data.field_3, 'content-field-3')
    setValue(content.data.field_4, 'content-field-4')

    if (content.data.meta_title) {
      document.title = content.data.meta_title
    }

    if (content.data.meta_keywords) {
      document
        .querySelector('meta[name=keywords]')
        .setAttribute('content', content.data.meta_keywords)
    }

    if (content.data.meta_description) {
      document
        .querySelector('meta[name=description]')
        .setAttribute('content', content.data.meta_description)
    }

    if (content.data.keyword) {
      toggleClass(document.body, `${content.data.keyword}-template`)
    }
  }
}

export default updateContent
