function getPathnameIndex(): number {
  const paths = [
    '/product/short-term-health-insurance/',
    '/product/short-term-health-insurance/census/',
    '/product/short-term-health-insurance/details/',
    '/product/short-term-health-insurance/compare-plans/',
    '/product/short-term-health-insurance/start-application/',
    '/product/short-term-health-insurance/quotes/',
    '/product/supplemental-health-insurance/',
    '/product/supplemental-health-insurance/census/',
    '/product/fixed-benefit-medical/',
    '/product/fixed-benefit-medical/census/',
    '/product/fixed-benefit-medical/quotes/',
    '/product/fixed-benefit-medical/details/',
    '/product/bridge-to-medicare/',
    '/product/bridge-to-medicare/census/',
    '/product/bridge-to-medicare/details/',
    '/product/bridge-to-medicare/start-application/',
    '/product/dental-and-vision/',
    '/product/dental-and-vision/census/',
    '/product/dental-and-vision/quotes/',
    '/product/medicare-supplement/'
  ]

  const { pathname } = window.location
  return paths.indexOf(pathname)
}

export default getPathnameIndex
