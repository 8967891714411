import { getEnvironmentName } from '@hc-frontend/business'
import {
  initSnowplowTracker,
  localStorageHcSessionDataProvider,
  hcSessionContextProvider,
  localStorageAnonymizedPiiDataProvider,
  anonymizedPiiContextProvider,
  ResponseSnowPlow
} from '@hc-frontend/third-party-snowplow'
import { localStoragePHChannelProvider } from 'utils/snowplow/context/ph-channel/localStorageDataProvider'
import { phChannelContextProvider } from 'utils/snowplow/context/ph-channel/phChannelContextGenerators'

function initSnowplow(): ResponseSnowPlow {
  return initSnowplowTracker({
    isProd: getEnvironmentName() === 'PROD',
    contexts: [
      hcSessionContextProvider(localStorageHcSessionDataProvider),
      anonymizedPiiContextProvider(localStorageAnonymizedPiiDataProvider),
      phChannelContextProvider(localStoragePHChannelProvider)
    ]
  })
}

export default initSnowplow
