import { sendLogsToDataDog } from 'utils/datadog'
import type { Metric } from 'web-vitals'
export const WEB_VITALS_EVENT_NAME = 'core-vitals'

/**
 * It sends the web vitals metric to DataDog
 * @param Metric metric - Metric - this is the metric object that is passed to the function.
 * @see https://github.com/GoogleChrome/web-vitals#metric
 */
export function sendWebVitalsMetric(metric: Metric) {
  sendLogsToDataDog(WEB_VITALS_EVENT_NAME, metric)
}
