import { IS_ENROLL } from 'core/constants/environment'
import toggleClass from 'utils/DOM/toggleClass'
import isSalesCenterSubDomain from 'utils/pivot/isSalesCenterSubDomain'

function updateAdaDisclaimer() {
  if (IS_ENROLL || isSalesCenterSubDomain()) {
    toggleClass('.js-ada-brokerage', 'd-none', true)
    toggleClass('.js-ada-brokerage', 'd-block', false)
    toggleClass('.js-ada-customer', 'd-none', false)
    toggleClass('.js-ada-customer', 'd-block', true)
  }
}

export default updateAdaDisclaimer
