import sanitizeParams from './sanitizeParams'

function getURLParams(url = window.location.search): PlainObject<string> {
  try {
    return decodeURIComponent(url)
      .replace(/^\?/, '')
      .split('&')
      .filter(x => x.length > 0)
      .map(raw => raw.split(/=(.+)/, 2))
      .map(sanitizeParams)
      .reduce((result, [prop, value]) => {
        result[prop] = value
        return result
      }, {})
  } catch (e) {
    return {}
  }
}

export default getURLParams
