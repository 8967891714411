import { Nullable } from '@hc-frontend/utils'
import { DEFAULT_PHONES } from '.'

export interface IGetNumberResponse {
  data: IData
  error?: Nullable<string>
  status: number
}

interface IData {
  placement: string
  logo: Nullable<string>
  advertiser: IAdvertiser
  phone: IPhone
  state: string
  zip: Nullable<string>
  impression_id: string
  schedule: ISchedule
}

interface ISchedule {
  sunday: IScheduleDay
  saturday: IScheduleDay
  tuesday: IScheduleDay
  Thursday: IScheduleDay
  wednesday: IScheduleDay
  friday: IScheduleDay
  monday: IScheduleDay
}

interface IScheduleDay {
  from: string
  to: string
}

interface IPhone {
  promo_number_formatted: string
  promo_number: string
  tracking_url: Nullable<string>
  link_invoca: string
  tty_formatted: string
}

interface IAdvertiser {
  name: string
  logo: Nullable<string>
}

export type MapResponse = {
  phone: {
    promo_number: string
    promo_number_formatted: string
    promo_number_formatted_dash: string
  }
}
export function mapResponse(
  response: IGetNumberResponse,
  ringPoolId: string
): MapResponse {
  const phoneData = response?.data?.phone

  let responseMapped: MapResponse = null
  if (response && phoneData) {
    responseMapped = {
      phone: {
        promo_number: phoneData.promo_number,
        promo_number_formatted: phoneData.promo_number_formatted.replace(
          /(\d{3})-(\d{3})-(\d{4})/,
          '($1) $2-$3'
        ),
        promo_number_formatted_dash: phoneData.promo_number_formatted
      }
    }
  }

  return responseMapped ? responseMapped : { phone: DEFAULT_PHONES[ringPoolId] }
}
