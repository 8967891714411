import replaceTCPA from 'pug/mixins/jornaya/replaceTCPA'
import html from '../tcpa.html'

let jornayaScriptLoaded = false

function loadJornayaScript() {
  if (!jornayaScriptLoaded) {
    const s = document.createElement('script')
    s.id = 'LeadiDscript_campaign'
    s.type = 'text/javascript'
    s.async = true
    s.src =
      '//create.lidstatic.com/campaign/0fccca9e-0aa6-e383-1618-6f20497c9c14.js?snippet_version=2'
    const LeadiDscript = document.getElementById('LeadiDscript')
    LeadiDscript.parentNode.insertBefore(s, LeadiDscript)
    jornayaScriptLoaded = true
  }

  if (!document.getElementById('tcpa-container')) {
    document.body.insertAdjacentHTML('beforeend', html)
  }
  replaceTCPA()
}

export default loadJornayaScript
