import memoize from '@hc-frontend/shared-assets/src/js/utils/memoize'
import fetchJSONHandleError from 'utils/NET/fetchJSONHandleError'
import { GEO_LOCATION } from 'core/constants/endpoints'

function getLocationByZip(zip: string) {
  return fetchJSONHandleError<LocationByZip[]>({
    url: `${GEO_LOCATION}/${zip}`
  })
}

export type LocationByZip = Partial<{
  zipCode: string
  stateId: number
  stateName: string
  countyName: string
  countyId: number
  stateAcronym: string
  fips: string
  displayName: string
  city: string
  ssa: string
}>

export default memoize(getLocationByZip) as typeof getLocationByZip
