import absmartly, { mergeConfig } from '@absmartly/javascript-sdk'
import removeProtocol from './utils/removeProtocol'
import isValidUserId from './utils/isValidUserId'
import runOverrides from './runOverrides'
import * as Keys from './environment'

const sdkConfig: ABSmartlySDKConfig = Object.create(null)
const sdkContext: SDKContext = null

/**
 * Settings used to initialize the SDK.
 * @returns The SDK configuration
 */
export const getSdkConfig = (): ABSmartlySDKConfig => ({ ...sdkConfig })

/**
 * Singleton: Only one instance of the SDK Context is created
 * @see https://github.com/absmartly/javascript-sdk#readme
 * @returns SDK Context instance
 */
export default function initSmartly(hcStorage?: ABSmartlyInitOptions): SDKContext | null {
  return null
}

/* istanbul ignore next */
function customLogger(context: SDKContext, eventName: string, data: PlainObject) {
  if (eventName === 'error') {
    console.warn(data)
  }
}
