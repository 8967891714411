import fetchJSONHandleError from 'utils/NET/fetchJSONHandleError'
import { BACKEND_PIVOT } from 'core/constants/endpoints'

function getQuoteByKey(quoteKey) {
  return fetchJSONHandleError({
    url: `${BACKEND_PIVOT}/v2/getQuote/${quoteKey}`
  })
}

export default getQuoteByKey
