import createStoreWithMiddleware from '../createStoreWithMiddleware'

const withMiddleware = createStoreWithMiddleware()

window.getState = withMiddleware.getState
window.updateState = withMiddleware.update

window.updateStore = withMiddleware.update // remove this, after migration

export default withMiddleware
