import forEach from 'utils/DOM/forEach'

function setAttribute(
  selector: StringOrNodeSelector,
  attribute: string,
  value: string
): void {
  forEach(selector, node => {
    node.setAttribute(attribute, value)
  })
}

export default setAttribute
