import type { EnvironmentPrefix } from '@hc-frontend/utils';
import {
  prefixEnvironment,
  prefixEnvironmentWithoutQA,
} from '@hc-frontend/utils';

/**
 * Get the prefix environment: qa./stg.
 *
 * @param prefix - A fixed prefix if this should be static
 * @returns If prefix is given and if it's of type `string`, it will be returned otherwise the current url will be used for guessing the environment
 *
 * @public
 *
 * @example
 * ```ts
 * const stg = getValue(); // if `window.location.hostname` is similar to a.domain.stg.healthcare.com
 * const qa = getValue('qa.');
 * ```
 */
function getValue(prefix?: EnvironmentPrefix): string {
  return typeof prefix === 'string' ? prefix : prefixEnvironment();
}

/**
 * Get the prefix environment: stg.
 *
 * @param prefix - A fixed prefix if this should be static
 * @returns If prefix is given and if it's of type `string`, it will be returned otherwise the current url will be used for guessing the environment
 * but always return `stg.` instead of `qa.`
 *
 * @public
 *
 * @example
 * ```ts
 * const stg1 = getValueWithoutQA(); // if `window.location.hostname` is similar to a.domain.qa.healthcare.com
 * const stg2 = getValueWithoutQA('stg.');
 * ```
 */
function getValueWithoutQA(prefix?: EnvironmentPrefix): string {
  return typeof prefix === 'string' ? prefix : prefixEnvironmentWithoutQA();
}

/**
 * Get the current Geo IP API endpoint to determine the current location of the user
 *
 * @returns The Geo IP API uri
 *
 * @public
 *
 * @example
 * ```ts
 * const uri = geoIpURI();
 * ```
 */
function geoIpURI(): string {
  return 'https://geoip.healthcare.com';
}

/**
 * Get the current location API endpoint to determine the current location of the user
 *
 * @param prefix - An environment to append to this url. If not provided then `window.location.hostname` is used to determine the environment
 * @returns The location API uri
 *
 * @public
 *
 * @example
 * ```ts
 * const uri = geoLocationURI('stg.');
 * ```
 */
function geoLocationURI(prefix?: EnvironmentPrefix): string {
  return `https://geolocation.${getValueWithoutQA(prefix)}healthcare.com`;
}

/**
 * Get the current SEM content API endpoint.
 *
 * @param prefix - An environment to append to this url. If not provided then `window.location.hostname` is used to determine the environment
 * @returns The SEM content API uri
 *
 * @public
 *
 * @example
 * ```ts
 * const uri = semContentURI('qa.');
 * ```
 */
function semContentURI(prefix?: EnvironmentPrefix): string {
  return `https://sem-content.${getValue(prefix)}healthcare.com`;
}

/**
 * Get the current SEM content static API endpoint.
 *
 * @param prefix - An environment to append to this url. If not provided then `window.location.hostname` is used to determine the environment
 * @returns The SEM content static API uri
 *
 * @public
 *
 * @example
 * ```ts
 * const uri = semContentStaticURI('qa.');
 * ```
 */
function semContentStaticURI(prefix?: EnvironmentPrefix): string {
  return `https://sem-content.static.${getValue(prefix)}healthcare.com`;
}

/**
 * Get the current CDN endpoint to fetch static assets for **PivotHealth**
 *
 * @param prefix - An environment to append to this url. If not provided then `window.location.hostname` is used to determine the environment
 * @returns The CDN uri
 *
 * @public
 *
 * @example
 * ```ts
 * const uri = CDNPivotURI('qa.');
 * ```
 */
function CDNPivotURI(prefix?: EnvironmentPrefix): string {
  return `https://cdn.${getValue(prefix)}pivothealth.com`;
}

/**
 * Get the current CDN endpoint to fetch static assets for **Healthcare.com**
 *
 * @param prefix - An environment to append to this url. If not provided then `window.location.hostname` is used to determine the environment
 * @returns The CDN uri
 *
 * @public
 *
 * @example
 * ```ts
 * const uri = CDNHealthCareURI('qa.');
 * ```
 */
function CDNHealthCareURI(prefix?: EnvironmentPrefix): string {
  return `https://cdn.${getValue(prefix)}healthcare.com`;
}

/**
 * Get the current plan service API endpoint to fetch plans for the user
 *
 * @param prefix - An environment to append to this url. If not provided then `window.location.hostname` is used to determine the environment
 * @returns The plan service API endpoint uri
 *
 * @public
 *
 * @example
 * ```ts
 * const uri = planServiceURI('qa.');
 * ```
 */
function planServiceURI(prefix?: EnvironmentPrefix): string {
  return `https://plans-srv.${getValue(prefix)}healthcare.com`;
}

/**
 * Get the current CRM middleware API endpoint uri
 *
 * @param prefix - An environment to append to this url. If not provided then `window.location.hostname` is used to determine the environment
 * @returns The CRM middleware API endpoint uri
 *
 * @public
 *
 * @example
 * ```ts
 * const uri = crmMiddlewareURI('qa.');
 * ```
 */
function crmMiddlewareURI(prefix?: EnvironmentPrefix): string {
  return `https://crm-middleware.${getValue(prefix)}pivothealth.com`;
}

/**
 * Get the current Web Metrics API endpoint uri
 *
 * @param prefix - An environment to append to this url. If not provided then `window.location.hostname` is used to determine the environment
 * @returns The Web Metrics API endpoint uri
 *
 * @public
 *
 * @example
 * ```ts
 * const uri = crmMiddlewareURI('qa.');
 * ```
 */
function webMetricsURI(prefix?: EnvironmentPrefix): string {
  return `https://web-metrics.${getValue(prefix)}healthcare.com`;
}

/**
 * Get the current Backend API endpoint uri
 *
 * @param prefix - An environment to append to this url. If not provided then `window.location.hostname` is used to determine the environment
 * @returns The backend endpoint URI
 *
 * @public
 *
 * @example
 * ```ts
 * const uri = getPivotBackendURI('qa.');
 * ```
 */
function getPivotBackendURI(prefix?: EnvironmentPrefix): string {
  return `https://backend.${getValue(prefix)}pivothealth.com`;
}

/**
 * Get the current Backend API endpoint uri
 *
 * @param prefix - An environment to append to this url. If not provided then `window.location.hostname` is used to determine the environment
 * @returns The backend endpoint URI
 *
 * @public
 *
 * @example
 * ```ts
 * const uri = getSessionManagerURI('qa.');
 * ```
 */
function getSessionManagerURI(prefix?: EnvironmentPrefix): string {
  return `https://session-manager-srv.${getValueWithoutQA(
    prefix
  )}healthcare.com`;
}

/**
 * Get the messaging service API endpoint uri
 *
 * @param prefix - An environment to append to this url. If not provided then `window.location.hostname` is used to determine the environment
 * @returns The messaging service URI
 *
 * @public
 *
 * @example
 * ```ts
 * const uri = getMessagingServiceURI('qa.');
 * ```
 */
function getMessagingServiceURI(prefix?: EnvironmentPrefix): string {
  return `https://messaging-service.${getValue(prefix)}pivothealth.com`;
}

/**
 * Get the Healthcare API endpoint uri
 *
 * @param prefix - An environment to append to this url. If not provided then `window.location.hostname` is used to determine the environment
 * @returns Healthcare api URI
 *
 * @public
 *
 * @example
 * ```ts
 * const uri = getHealthcareApiURI('qa.');
 * ```
 */
function getHealthcareApiURI(prefix?: EnvironmentPrefix): string {
  return `https://api.${getValue(prefix)}healthcare.com`;
}

/**
 * Get the SponsoredAds API endpoint uri
 *
 * @param prefix - An environment to append to this url. If not provided then `window.location.hostname` is used to determine the environment
 * @returns SponsoredAds api URI
 *
 * @public
 *
 * @example
 * ```ts
 * const uri = getSponsoredAdsApiURI('qa.');
 * ```
 */
function getSponsoredAdsApiURI(prefix?: EnvironmentPrefix): string {
  return `https://searchads.${getValueWithoutQA(prefix)}healthcare.com`;
}

export {
  CDNHealthCareURI,
  CDNPivotURI,
  crmMiddlewareURI,
  geoIpURI,
  geoLocationURI,
  getHealthcareApiURI,
  getMessagingServiceURI,
  getPivotBackendURI,
  getSessionManagerURI,
  getSponsoredAdsApiURI,
  getValue,
  getValueWithoutQA,
  planServiceURI,
  semContentStaticURI,
  semContentURI,
  webMetricsURI,
};
