import defaultStorage from '@hc-frontend/shared-assets/src/js/sem/storage-manager/defaultStorage'
import { deepClone } from '@hc-frontend/utils'
import storage, { WebStorage } from 'proxy-storage'

function resetHcStorage(hcStorage) {
  let newStorage = hcStorage

  if (hcStorage) {
    newStorage = deepClone(defaultStorage)
    newStorage.agent_id = hcStorage.agent_id
    newStorage.healthcareUserId = hcStorage.healthcareUserId
    storage.setItem('hc', newStorage)
  }

  const cookieStore = new WebStorage('cookieStorage')
  const host = window.location.hostname.split('.')
  const domain = `.${host[host.length - 2]}.${host[host.length - 1]}`

  cookieStore.removeItem('healthcareSession', {
    domain,
    expires: { hours: -1 }
  })

  return newStorage
}

export default resetHcStorage
