import getAgentHierarchy from 'core/services/getAgentHierarchy'
import getEnvironment from 'utils/getEnvironment'
import getDTCPort from 'utils/pivot/environment/getDTCPort'
import { type RedirectStatus, convertPathname, buildURL } from './utils'

function getEnrollUrl(): string {
  const { search, pathname, protocol } = window.location
  const newPathname = convertPathname(pathname, true)
  const environment = getEnvironment()
  const subdomain = 'enroll.'
  const newPort = getDTCPort()

  const urlParams = new URLSearchParams(search)
  urlParams.delete('agent_id')
  const newSearch = `?${urlParams.toString()}`

  return buildURL({
    protocol,
    subdomain,
    environment,
    port: newPort,
    pathname: newPathname,
    search: newSearch
  })
}

function getNewAgentUrl(newAgentId: string): string {
  const { search, pathname, origin } = window.location

  const urlParams = new URLSearchParams(search)
  urlParams.set('agent_id', newAgentId)
  const newSearch = urlParams.toString()

  return `${origin}${pathname}?${newSearch}`
}

export default function agentHierarchy({
  agent
}: RedirectStatus): Promise<RedirectStatus> {
  return getAgentHierarchy(agent.agent_id).then(hierarchy => {
    const topAgent = hierarchy && hierarchy[hierarchy.length - 1]
    const currentAgent = hierarchy && hierarchy.find(a => a.agent_id === agent.agent_id)

    if (topAgent && !topAgent.is_active_flag) {
      return { redirectTo: getEnrollUrl() }
    }

    if (currentAgent && !currentAgent.is_active_flag) {
      return { redirectTo: getNewAgentUrl(topAgent.agent_id) }
    }

    return { continue: true }
  })
}
