import { range } from '@hc-frontend/utils'
import { IS_ENROLL } from 'core/constants/environment'

const MODAL_ID = '#redirect-modal'
const TIMER_VALUE_ID = '#redirect-timer-value'
const TIMER_MAX = IS_ENROLL ? 10 : 5
const TIMER_MIN = 0
const $modal = $(MODAL_ID)

function showModal({ onShow = defaultOnShow, onHide = defaultOnHide }) {
  $modal
    .modal('show')
    .on('shown.bs.modal', onShow)
    .on('hidden.bs.modal', onHide)
}

function hideModal() {
  $modal.modal('hide')
}

function defaultOnShow() {
  const updateTimerValue = v => $(TIMER_VALUE_ID).text(v)
  range(TIMER_MAX, TIMER_MIN).forEach((timerValue, i) => {
    setTimeout(() => {
      updateTimerValue(timerValue)

      if (timerValue === TIMER_MIN) {
        hideModal()
      }
    }, 1000 * i)
  })
}

function defaultOnHide() {}

export default showModal
