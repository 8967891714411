import type { JSONObject, PromNull } from '@hc-frontend/utils';
import { fetchJSON } from '@hc-frontend/utils';

import { getSponsoredAdsApiURI } from '../config';
import type { IFetchSponsoredAdsPayload } from './fetch-sponsored-ads-payload';
import type { IFetchSponsoredAdsResponse } from './fetch-sponsored-ads-service-response';

/**
 * Fetch all possible locations that match the `zip` code
 *
 * @param payload - The payload to send to search ads endpoint
 * @returns The array of possible locations related with the zip, otherwise an empty array will be returned
 *
 * @public
 *
 * @example
 * ```ts
 * const locations = await fetchSponsoredAdsService('3133');
 * ```
 */
async function fetchSponsoredAdsService(
  payload: IFetchSponsoredAdsPayload
): PromNull<IFetchSponsoredAdsResponse> {
  const url = `${getSponsoredAdsApiURI()}/api/searchads`;
  const { parsedBody } = await fetchJSON<IFetchSponsoredAdsResponse>(url, {
    body: payload as unknown as JSONObject,
  });

  return parsedBody?.Id ? parsedBody : null;
}

export { fetchSponsoredAdsService };
