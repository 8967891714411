import type { StatusType } from '@datadog/browser-logs'
import getLogger from './getLogger'

export type DataDogLoggerArgs = {
  message: string
  messageContext?: object
  status?: StatusType
}

export async function sendLogsToDataDog(
  message: DataDogLoggerArgs['message'],
  messageContext?: DataDogLoggerArgs['messageContext'],
  status: DataDogLoggerArgs['status'] = 'info'
) {
  getLogger().log(message, messageContext, status)
}
