import clickEventName from 'utils/DOM/clickEventName'
import scrollTo from 'core/DOM/scrollTo'

const maxBlocks = 2
let sentinel
let $anchor = null
let anchorId = ''
let count = 0

function scrollToSentinel() {
  const $sentinel = $(sentinel)
  if ($sentinel.length > 0) {
    const sentinelTop = $sentinel.offset().top
    scrollTo(sentinelTop)
  }
}

function scrollCheck(anchor) {
  if (anchor.length > 0) {
    const anchorTop = anchor.offset().top
    scrollTo(anchorTop)
    count = 0
    anchorId = ''
  } else {
    scrollToSentinel()
  }
}

function setScrollToLazyHandler(sentinelRef) {
  sentinel = sentinelRef
  $('body').on(`${clickEventName}.scrollToLazy`, '.scrollToLazy', function() {
    anchorId = $(this).attr('href') || $(this).attr('data-href')
    $anchor = $(anchorId)

    scrollCheck($anchor)
  })

  window.appStateUpdatedQueue = window.appStateUpdatedQueue || []
  window.appStateUpdatedQueue.push(function() {
    if (anchorId && count <= maxBlocks) {
      $anchor = $(anchorId)
      scrollCheck($anchor)
      count++
    }
  })
}

export default setScrollToLazyHandler
