import { IS_ENROLL, CONSUMER_ORGANIC_AGENT_ID } from 'core/constants/environment'
import { ENROLL_PROD, PIVOT_PROD } from 'core/constants/endpoints'

function getProdRedirectLink(hcStorage) {
  if (IS_ENROLL) {
    return ENROLL_PROD
  }

  let pivotUrl = PIVOT_PROD
  const currentAgent = hcStorage.agent_id

  if (currentAgent !== CONSUMER_ORGANIC_AGENT_ID) {
    pivotUrl += `?agent_id=${currentAgent}`
  }

  return pivotUrl
}

export default getProdRedirectLink
