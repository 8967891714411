import { WebStorage } from 'proxy-storage'
import { version } from '../../package.json'

const sessionStorage = new WebStorage('sessionStorage')

function setData(key, value) {
  try {
    sessionStorage.setItem(`${version}__${key}`, value)
  } catch (e) {} // eslint-disable-line
}

function getData(key) {
  return sessionStorage.getItem(`${version}__${key}`)
}

export default {
  setData,
  getData
}
