import isSalesCenterAgent from 'utils/pivot/agent/isSalesCenterAgent'
import { RedirectStatus } from './utils'

export default function initStatus(hcStorage, agent): Promise<RedirectStatus> {
  const base: RedirectStatus = {
    continue: true,
    organicRedirect: false,
    hcStorage,
    windowLocation: window.location
  }

  if (agent && agent.agent_id) {
    return isSalesCenterAgent(agent.agent_id).then(isSalesCenterAgency => ({
      ...base,
      isSalesCenterAgency,
      agent
    }))
  } else {
    return Promise.resolve({
      ...base,
      isSalesCenterAgency: false
    })
  }
}
