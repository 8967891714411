import { SALES_CENTER_AGENCY } from 'core/constants/environment'
import getAgent from 'core/services/getAgent'

function isSalesCenterAgent(agent_id: number): Promise<boolean> {
  return getAgent(agent_id).then(({ data }) => {
    return data && data.agency_id === SALES_CENTER_AGENCY
  })
}

export default isSalesCenterAgent
