export const ADD_PLAN = 'Your plan was added.'
export const REMOVE_PLAN = 'Your plan was removed.'
export const SIMILAR_PLANS =
  'This coverage duration is no longer available but here are additional coverage solutions you can select from.'
export const REQUIRED_ERROR_MESSAGE =
  'This field is required. Please fill it with valid information.'
export const ABA_ERROR_MESSAGE = 'Please enter a ABA Routing Number.'

export const ADD_EXTENDED = 'Additional coverage period(s) successfully added'
export const REMOVE_EXTENDED = 'Additional coverage period(s) successfully removed'
export const WELCOME_RETURNING_VISITOR = 'Welcome back'
export const UPDATED_START_FROM_SERVER = 'The start date was updated'
export const UPDATED_START_FROM_SERVER_APPLICATION =
  'The start date was updated, check it and try again'

export const INVALID_PROFILE = 'The quote you were looking for has expired.'
export const BTM_CONSECUTIVE_POLICIES_NOTE =
  'Rates subject to change during the second and third coverage periods.'

export const NO_PLAN_MATCHES =
  'No Plan Matches. Your filters have been reset to the latest selection.'
export const MAIN_PLAN_UPDATED = 'Your selected plan has been updated.'

export const FILTER_CHANGED = 'Your filters have been reset to show available options'
