import { IS_ENROLL } from 'core/constants/environment'
import { CDN_PIVOT, PIVOT_DOMAIN } from 'core/constants/endpoints'

const pathBase = IS_ENROLL ? '/' : `/product/${process.env.PRODUCT}/`

export const HOME = pathBase
export const FAQ = `${pathBase}faq-short-term-medical/`
export const APPLICATION = `${pathBase}application/`
export const ADD_ONS = `${pathBase}add-ons/`
export const CENSUS = `${pathBase}census/`
export const COMPARE_PLANS = `${pathBase}compare-plans/`
export const DETAILS = `${pathBase}details/`
export const ELIGIBILITY = `${pathBase}eligibility/`
export const ENROLL_NOW = `${pathBase}enroll-now/`
export const FAS = `${pathBase}fas/`
export const AFLAC = `${pathBase}aflac/`
export const LANDING = pathBase
export const QUOTES = `${pathBase}quotes/`
export const START_APPLICATION = `${pathBase}start-application/`
export const THANK_YOU = `${pathBase}thank-you/`
export const ABOUT = `${pathBase}about/`
export const BUYERS_GUIDE = `${pathBase}short-term-medical-buyers-guide/`
export const WELCOME_BACK = `${pathBase}welcome-back/`
export const WELCOME_BACK_QUOTE = `${pathBase}welcome-back-quote/`
export const CONTACT_US = `${pathBase}contact-us/`
export const GET_BROCHURE_BTM = `${CDN_PIVOT}/resources/bridge-to-medicare/BTMBrochure_032519.pdf`
export const REVIEWS = `${PIVOT_DOMAIN}/pivot-health-reviews/`
export const STUDENT_LP = `${pathBase}student-health-insurance/SHI2`
export const TRINET_LP = `${pathBase}trinet/`
export const AMERITAS_PRIOR_COVERAGE = `${pathBase}prior-coverage/`
export const REDIRECT = `${pathBase}redirect/`
