function getSimpleStore() {
  let cache

  const simple = {
    update(data) {
      cache = data
    },
    getState() {
      return cache
    }
  }

  return simple
}

export default getSimpleStore
