import { PHChannelData } from 'Snowplow'

const localStoragePHChannelProvider = {
  readPHChannelContextData: (): PHChannelData => {
    try {
      const hcData = JSON.parse(window.localStorage.getItem('hc') || 'null')

      return {
        channel: (hcData.channel.channelName || '').toLocaleLowerCase(),
        agency_id: hcData.agency_id,
        agent_id: String(hcData.agent_id)
      }
    } catch (error) {
      return { channel: null, agency_id: null, agent_id: null }
    }
  }
}

export { localStoragePHChannelProvider }
