import doRedirect from 'utils/NET/doRedirect'
import agentHierarchy from './agent-hierarchy'
import salesCenterLogin from './agents-login'
import agents from './agents'
import channelName from './channel-name'
import enroll from './enroll'
import initStatus from './init-status'
import organicUser from './organic-user'
import renewalParam from './renewal-param'
import stopOnLocal from './stop-on-local'
import {
  type RedirectStatus,
  type RedirectStatusOperation,
  doOrganicRedirect,
  mergeStatus
} from './utils'
import wrongParams from './wrong-params'

function canContinue(status: RedirectStatus): boolean {
  return status.continue && !status.redirectTo && !status.organicRedirect
}

function waitForPossibleRedirect(
  status: RedirectStatus
): Promise<RedirectStatus> | RedirectStatus {
  if (status.organicRedirect) {
    return doOrganicRedirect(status.agent).then(() => status)
  } else if (status.redirectTo) {
    return doRedirect(status.redirectTo).then(() => status)
  } else {
    return status
  }
}

function check(predicate: RedirectStatusOperation): RedirectStatusOperation {
  return (status: RedirectStatus) => {
    if (canContinue(status)) {
      const operationResult = predicate(status)
      return mergeStatus(status, operationResult)
        .then(waitForPossibleRedirect)
        .then(newStatus => (canContinue(newStatus) ? newStatus : { continue: false }))
    } else {
      return status
    }
  }
}

export default function checkForRedirects(hcStorage, agent) {
  return initStatus(hcStorage, agent)
    .then(check(wrongParams))
    .then(check(stopOnLocal)) // COMMENT THIS STEP TO PERFORM REDIRECTS ON LOCAL ENVIRONMENT
    .then(check(salesCenterLogin))
    .then(check(renewalParam))
    .then(check(channelName))
    .then(check(organicUser))
    .then(check(enroll))
    .then(check(agents))
    .then(check(agentHierarchy))
}
