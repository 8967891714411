import { findApplicant } from '@hc-frontend/business'
import { Gender, TobaccoUse } from '@hc-frontend/entities'
import { Nullable } from '@hc-frontend/utils'
import { cleanDomainName } from 'utils/cleanDomainName'

export type GetNumberPayload = {
  product: string
  src: string
  publisher_click_id: string
  session_id: string
  user_id: string
  sub_id: number
  experiment_id: string
  variation_id: string
  device: string
  placement: string
  state: string // state_acronym
  zip: string
  request_url: string
  site: string
  profile: Profile
  call_type: string
  iv_parameter: string
  agent_id: string
}
type Tobacco = 'yes' | 'no'
type Profile = {
  qle: Nullable<number>
  family_size: Nullable<number>
  fpl: Nullable<number | string>
  tobacco: Nullable<Tobacco>
  gender: Nullable<Gender>
  age: Nullable<number>
}

export function makePayload(
  hcStorage: Pivot.Store,
  ringPoolId: string,
  idLocation: string
): GetNumberPayload {
  const domainName = cleanDomainName({ hostName: window.location.hostname })
  const applicant = findApplicant(hcStorage.members)
  const useTobacco = applicant?.tobacco
    ? applicant.tobacco === TobaccoUse.Yes
      ? 'yes'
      : 'no'
    : null

  const isOrganic = ringPoolId === 'organic'
  return {
    product: 'STM',
    src: isOrganic ? 'ORGANIC' : '',
    publisher_click_id: '',
    user_id: hcStorage.healthcareUserId,
    session_id: hcStorage.healthcareSession,
    sub_id: 0,
    experiment_id: '',
    variation_id: '',
    device: hcStorage.Device,
    placement: 'IN_HEADER',
    state: hcStorage.location.state_acronym || '',
    zip: hcStorage.location.zip_code || '',
    request_url: `${domainName}${window.location.pathname}${
      idLocation ? `#${idLocation}` : ''
    }`,
    iv_parameter: hcStorage.bt_sid,
    agent_id: hcStorage.agent_id,
    site: domainName,
    profile: {
      qle: hcStorage?.life_event || null,
      family_size: hcStorage.members.length,
      fpl: hcStorage?.household?.fpl || null,
      tobacco: useTobacco,
      gender: applicant?.gender || null,
      age: applicant?.age || null
    },
    call_type: 'bundle'
  }
}
