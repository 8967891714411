import clickEventName from 'utils/DOM/clickEventName'
import scrollTo from './scrollTo'

function setScrollToHandler(fixTop = 0) {
  $('body').on(clickEventName, '.scrollTo', function() {
    const anchorId = $(this).attr('href') || $(this).attr('data-href')
    const anchor = $(anchorId)

    if (anchor.length > 0) {
      const anchorTop = anchor.offset().top - fixTop
      scrollTo(anchorTop)
    }
  })
}

export default setScrollToHandler
