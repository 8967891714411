import beforeYouGoHandler from '@hc-frontend/shared-assets/src/exit-messaging/core/beforeYouGo'
import timeFromBegin from '@hc-frontend/shared-assets/src/js/utils/time/timeFromBegin'

function connectMe({
  startIn = 5000,
  maxTimeInactive = 45000,
  aggressive,
  placement,
  viewData = {},
  experienceName,
  device,
  hcData,
  render,
  isMultiStep,
  moduleId = '1205',
  displayBYGPercentage = 0,
  enableMouseExitIntent = false,
  cookieExpire = 1,
  cookieName = 'viewedHCModal'
}: ConnectMeArgs) {
  const beforeYouGo = beforeYouGoHandler(cookieExpire, cookieName)

  beforeYouGo({
    startIn: timeFromBegin(startIn),
    maxTimeInactive,
    displayBYGPercentage,
    enableMouseExitIntent,
    callback: () =>
      render(
        {
          location: hcData.location,
          ...viewData,
          hcData,
          placement,
          moduleId,
          isMultiStep,
          experienceName
        },
        true
      ),
    aggressive,
    experienceName,
    device: device || hcData.Device
  })
}

export default connectMe
