import fetchJSONHandleError from 'utils/NET/fetchJSONHandleError'
import mergeData from '../getApplicantContext/mergeData'
import { SESSION } from 'core/constants/endpoints'

function getApplicantContextBySessionId(sessionId, hcStorage, updateLocation) {
  return fetchJSONHandleError({
    url: `${SESSION}/applicantContext/session/${sessionId}.json`
  })
    .then(mergeData(hcStorage, updateLocation))
    .catch(() => {
      return hcStorage
    })
}

export default getApplicantContextBySessionId
