import getURLParams from 'utils/DOM/getURLParams'

const parametersToRead = [
  'session_id',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'zip',
  'county',
  'fname',
  'lname',
  'gender',
  'dob',
  'email',
  'spouse',
  'dob_s',
  'gender_s',
  'phone_number',
  'coverage',
  'period',
  'planid',
  'policies',
  'sdate',
  'endDate'
] as const

export type ParameterToRead = typeof parametersToRead[number]

const pickParameters = (
  parameters: Record<string, string>,
  pick: Array<ParameterToRead>
) => {
  let result: null | Record<ParameterToRead, string> = null
  for (const key of pick)
    if (parameters[key]) {
      if (!result) result = {} as Record<ParameterToRead, string>
      result[key] = parameters[key]
    }

  return result
}

export default function readFromQueryParameters(): Pivot.Session | undefined {
  const queryParams = getURLParams()

  const existData = parametersToRead.some(param => queryParams[param])

  if (!existData) {
    return undefined
  }

  return {
    sessionId: queryParams.session_id,
    user: pickParameters(queryParams, [
      'fname',
      'lname',
      'email',
      'phone_number',
      'gender',
      'dob',
      'gender_s',
      'dob_s'
    ]),
    browser: pickParameters(queryParams, [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_content'
    ]),
    quote: pickParameters(queryParams, [
      'zip',
      'county',
      'coverage',
      'period',
      'planid',
      'policies',
      'sdate',
      'endDate'
    ]),
    cart: null,
    createdAt: null,
    updatedAt: null,
    expiresAt: null
  }
}
