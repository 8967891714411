export default {
  u65: {
    sat: [
      [11, 0],
      [15, 0]
    ],
    sun: [],
    default: [
      [8, 0],
      [19, 0]
    ]
  },
  o65: {
    sat: [
      [11, 0],
      [15, 0]
    ],
    sun: [],
    default: [
      [8, 15],
      [18, 45]
    ]
  },
  fe: {
    sat: [
      [11, 0],
      [15, 0]
    ],
    sun: [],
    default: [
      [8, 15],
      [18, 45]
    ]
  }
}
