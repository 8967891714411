import { ContextFilter, SelfDescribingJson } from '@snowplow/browser-tracker'
import { PHChannelDataProvider } from 'Snowplow'

function phChannelContextFilter(dataProvider: PHChannelDataProvider): ContextFilter {
  return () => {
    const data = dataProvider.readPHChannelContextData()
    return data.agency_id != null && data.agent_id != null && data.channel != null
  }
}

function phChannelContextGenerator(dataProvider: PHChannelDataProvider) {
  return (): SelfDescribingJson => {
    const data = dataProvider.readPHChannelContextData()
    return {
      schema: 'iglu:com.healthcare/ph_channel/jsonschema/1-0-1',
      data: {
        channel: data.channel,
        agency_id: data.agency_id,
        agent_id: data.agent_id
      }
    }
  }
}

export { phChannelContextGenerator, phChannelContextFilter }
