export type BuildURLParams = {
  protocol: string
  subdomain: string
  port: string
  environment?: string
  pathname: string
  search: string
}

export function buildURL({
  protocol,
  subdomain,
  environment = '',
  port,
  pathname,
  search
}: BuildURLParams) {
  if (environment.length > 0) {
    if (environment.includes('prd')) {
      environment = ''
    } else if (!environment.endsWith('.')) {
      environment = `${environment}.`
    }
  }

  if (subdomain && subdomain.length > 0 && !subdomain.endsWith('.')) {
    subdomain = `${subdomain}.`
  }

  return `${protocol}//${subdomain}${environment}pivothealth.com${
    port === '80' ? '' : ':' + port
  }${pathname}${search}`
}
