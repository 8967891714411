import { WebStorage } from 'proxy-storage'
import { version } from '../../package.json'

const sessionStorage = new WebStorage('sessionStorage')

function sessionCachePromise(func, prefix) {
  return function sessionCacheHandles(...args) {
    const key = JSON.stringify(args)
    const cache = sessionStorage.getItem(`${version}__${prefix}__${key}`)

    return cache
      ? Promise.resolve(cache)
      : func(...args).then(response => {
          if (response != null && !response.error) {
            sessionStorage.setItem(`${version}__${prefix}__${key}`, response)
          }

          return response
        })
  }
}

export default sessionCachePromise
