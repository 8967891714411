import { type IMember, countChildren } from '@hc-frontend/business'
import type { RedirectStatus } from '../utils'

type ToAddParameters = Array<{ key: string; value: string }>
type ToRemoveParameters = string[]

const CHILD_PARAMS = ['dob_', 'gender_']

function isChildParam(param: string): boolean {
  const paramsParts = param.split('_')
  const paramsLength = paramsParts.length
  return !!CHILD_PARAMS.find(
    i => param.startsWith(i) && paramsLength > 1 && !isNaN(+paramsParts[1])
  )
}

function checkForWrongChildren(currentMembers: IMember[], search) {
  const toAdd: ToAddParameters = []
  const toRemove: ToRemoveParameters = []
  const queryParams = new URLSearchParams(search)
  const currentChildren = countChildren(currentMembers)
  const limit = currentChildren === 10 ? 10 : 9
  const mapConversion: number[] = []

  let cursor = currentChildren
  let attributeName: string, index: string

  for (const param of queryParams.keys()) {
    if (isChildParam(param)) {
      ;[attributeName, index] = param.split('_')

      if (+index > limit) {
        if (!mapConversion[index]) {
          mapConversion[index] = cursor++
        }

        toAdd.push({
          key: `${attributeName}_${mapConversion[index]}`,
          value: queryParams.get(param)
        })
        toRemove.push(param)
      }
    }
  }

  return { toAdd, toRemove }
}

function getNewUrl(
  location: Location,
  toAdd: ToAddParameters,
  toRemove: ToRemoveParameters
): string {
  const { search, protocol, host, pathname } = location
  const queryParams = new URLSearchParams(search)

  toAdd.forEach(q => queryParams.set(q.key, q.value))
  toRemove.forEach(q => queryParams.delete(q))

  return `${protocol}//${host}${pathname}?${queryParams.toString()}`
}

export default function wrongMembers({
  hcStorage,
  windowLocation
}: RedirectStatus): RedirectStatus {
  const currentMembers: IMember[] = hcStorage.members
  const toAdd: ToAddParameters = []
  const toRemove: ToRemoveParameters = []

  const childrenStatus = checkForWrongChildren(currentMembers, windowLocation.search)

  toAdd.push(...childrenStatus.toAdd)
  toRemove.push(...childrenStatus.toRemove)

  if (toAdd.length > 0 || toRemove.length > 0) {
    return { redirectTo: getNewUrl(windowLocation, toAdd, toRemove) }
  } else {
    return { continue: true }
  }
}
