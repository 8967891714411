import vanillaTextMask from 'vanilla-text-mask/dist/vanillaTextMask'

import getById from 'utils/DOM/getById'
import setValueText from './setValueText'
import updateDOM from './updateDOM'
import setErrorMessage from './setErrorMessage'

/** Values you can set when calling `baseControllerInput` */
export interface ControllerBuilderConfig {
  maskConfig?: any
  getErrorMessage: (state: any, date?: any) => string
  parseSetValue?: (value: string) => string
}

/** Configuration for a standard controller */
export interface BaseControllerInput {
  input: any
  typeMember: any
  callOnValidate?: any
  callOnFocus: any
  getSdate: any
  showError?: any
}

/** A standard input controller, used to access a form input */
export interface BaseController {
  reset: () => void
  setValue: (value: string) => void
  getState: () => any
  getStatus: () => {
    valid: boolean
    value: string
  }
  validate: () => void
  setCallOnValidate: (validateFn: any) => void
  setCallOnFocus: (focusFn: any) => void
  destroy: () => void
  makeOptional?: () => void
}

/**
 * Input controller factory factory
 * @param config - Configuration object for the controller factory/builder
 * @returns A standard controller factory function
 */
export function baseControllerInput(
  config: ControllerBuilderConfig
): (BaseControllerInput) => BaseController {
  return ({
    input,
    typeMember,
    callOnValidate = undefined,
    callOnFocus,
    getSdate,
    showError = setErrorMessage
  }: BaseControllerInput) => {
    const id = input.id || input.name
    const state = {
      input,
      typeMember,
      wrap: getById(`wrap-${id}`),
      errorWrap: getById(`error-${id}`),
      valid: false,
      error: false,
      showError
    }

    let maskedInputController
    if (config.maskConfig) {
      maskedInputController = vanillaTextMask(
        Object.assign({ inputElement: input }, config.maskConfig)
      )
    }

    const validate = state => {
      const error = config.getErrorMessage(state, getSdate ? getSdate() : undefined)

      if (error) {
        state.showError(state, error)
      } else {
        state.error = false
        state.valid = true
      }

      updateDOM(state)
      if (callOnValidate) {
        callOnValidate(state)
      }
    }

    function handleBlur() {
      validate(state)
    }

    input.addEventListener('blur', handleBlur)

    function handleFocus() {
      if (state.error) {
        state.error = false
        updateDOM(state)
      }
      if (callOnFocus) {
        callOnFocus(state)
      }
    }

    input.addEventListener('focus', handleFocus)

    return {
      reset: () => {
        state.error = false
        state.valid = false
        state.input.value = ''

        updateDOM(state)
      },
      setValue: value => {
        setValueText(state, config.parseSetValue ? config.parseSetValue(value) : value)
      },
      getState: () => state,
      getStatus: () => ({
        valid: state.valid,
        value: state.input.value.trim()
      }),
      validate: () => {
        validate(state)
      },
      setCallOnValidate: validateFn => {
        callOnValidate = validateFn
      },
      setCallOnFocus: focusFn => {
        callOnFocus = focusFn
      },
      destroy: () => {
        if (maskedInputController) {
          maskedInputController.destroy()
        }
        input.removeEventListener('blur', handleBlur)
        input.removeEventListener('focus', handleFocus)
      }
    }
  }
}

export default baseControllerInput
