import { SnowplowTrigger } from 'Snowplow'
import clickEventName from 'utils/DOM/clickEventName'
import { registerSnowplowTriggers } from 'utils/snowplow/events/userClick/registerSnowplowTriggers'

const generalEvents: SnowplowTrigger[] = [
  {
    element: '#about-nav__buyer-guide-link',
    description: 'Header - Buyers Guide',
    elementTrigger: clickEventName,
    eventType: 'click',
    placement: 'header-main-nav',
    valueParser: () => 'Buyers Guide'
  },
  {
    element: '#about-nav__about-link',
    description: 'Header - About Pivot Health',
    elementTrigger: clickEventName,
    eventType: 'click',
    placement: 'header-main-nav',
    valueParser: () => 'About Pivot Health'
  },
  {
    element: '#about-nav__call-action, #headerCallIcon, #Header__contact-action--call',
    description: 'Header - Call Action',
    elementTrigger: clickEventName,
    eventType: 'click',
    placement: 'header-main-nav',
    valueParser: () => 'Call Action'
  },
  {
    element: '#Header__contact-action--email',
    description: 'Header - Email action',
    elementTrigger: clickEventName,
    eventType: 'click',
    placement: 'header-main-nav',
    valueParser: () => 'Email action'
  },
  {
    element: '#Header__contact-action--chat, .call-animation, .Header__contact-icon',
    description: 'Header - Chat action',
    elementTrigger: clickEventName,
    eventType: 'click',
    placement: 'header-main-nav',
    valueParser: () => 'Chat action'
  },
  {
    element: '#header-modal-submit',
    description: 'Header - Modal - Connect Me to an expert',
    elementTrigger: clickEventName,
    eventType: 'click',
    placement: 'modal-dialog',
    valueParser: () => 'Call Action Modal'
  },
  {
    element: '#header-modal__close-button, #header-modal-content__close-button',
    description: 'Header - Modal - Call action - No thanks',
    elementTrigger: clickEventName,
    eventType: 'click',
    placement: 'modal-dialog',
    valueParser: () => 'Call Action Modal'
  },
  {
    element: '.options__nav-item--chat',
    description: 'Header - Modal - Live chat action tab',
    elementTrigger: clickEventName,
    eventType: 'click',
    placement: 'modal-dialog',
    valueParser: () => 'Chat Action Modal'
  },
  {
    element: '.chat_link',
    description: 'Header - Modal - Start Chat',
    elementTrigger: clickEventName,
    eventType: 'click',
    placement: 'modal-dialog',
    valueParser: () => 'Chat Action Modal'
  },
  {
    element: '#chat-content__close-button',
    description: 'Header - Modal - Chat tab - No thanks',
    elementTrigger: clickEventName,
    eventType: 'click',
    placement: 'modal-dialog',
    valueParser: () => 'Call Action Modal'
  }
]

export function registerGeneralSpListeners() {
  registerSnowplowTriggers(generalEvents)
}
