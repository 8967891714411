import showRedirectModal from 'pug/mixins/redirectModal'
import doRedirect from 'utils/NET/doRedirect'

export function doOrganicRedirect(agent) {
  const { search, pathname, origin } = window.location
  const urlParams = new URLSearchParams(search)

  const currentAgentIdParam = urlParams.get('agent_id')

  if (!currentAgentIdParam) {
    urlParams.append('agent_id', agent.agent_id)
  } else {
    urlParams.set('agent_id', agent.agent_id)
  }

  return new Promise(resolve =>
    showRedirectModal({
      onHide: () => {
        resolve(doRedirect(`${origin}${pathname}?${urlParams.toString()}`))
      }
    })
  )
}
