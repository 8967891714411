import toggleClass from 'utils/DOM/toggleClass'
import references from './references'

function onUpdate(placement) {
  return (connectMeState, prevConnectMeState) => {
    const module = connectMeState.modules[placement]

    if (module.status && module.status !== prevConnectMeState.modules[placement].status) {
      const moduleRefs = references[placement]
      toggleClass(moduleRefs.form, 'd-none', true)
      toggleClass(
        moduleRefs.statusElem,
        prevConnectMeState.modules[placement].status,
        false
      )
      toggleClass(moduleRefs.statusElem, module.status, true)
    }
  }
}

export default onUpdate
