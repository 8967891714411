import { stringToDate } from '@hc-frontend/utils'
import { type IMember, MemberType, setDOBToMember } from '@hc-frontend/business'
import isString from 'utils/validation/isString'

const standardizeMembers = (
  members: { type?: string; dob?: string; stringDOB?: string }[]
): IMember[] => {
  return members.map(member => {
    let newMember = { ...member }

    if (newMember.dob && isString(newMember.dob)) {
      const dob = stringToDate(newMember.dob)
      newMember = setDOBToMember(newMember, dob)
    }

    if (!newMember.stringDOB) {
      newMember.stringDOB = ''
    }

    if (newMember.type) {
      newMember.type = newMember.type.toLowerCase() as MemberType
    }

    return newMember
  }) as unknown as IMember[]
}

export default standardizeMembers
