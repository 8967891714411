import { propAccessor } from '@hc-frontend/utils'
import { MULTI_CARRIER_ENABLED } from 'core/constants/environment'
import getMultiCarrierEligibility from 'core/services/getMultiCarrierEligibility'
import store from 'utils/store/withMiddleware'

export type UpdateMultiCarrierEligibilityOptional = {
  stateAcronym?: string
}

export default async (
  state: Pivot.State,
  optional?: UpdateMultiCarrierEligibilityOptional
) => {
  if (MULTI_CARRIER_ENABLED && !state.signedDetails) {
    const stateAcronym =
      propAccessor(optional, 'stateAcronym') ||
      propAccessor(state, 'hcStorage.location.state_acronym')
    const agentId = propAccessor(state, 'hcStorage.agent_id')

    const multiCarrierEligibility = await getMultiCarrierEligibility(
      stateAcronym,
      agentId
    )

    if (!multiCarrierEligibility.error)
      return store.update({
        eligibilityMonths: multiCarrierEligibility.maxDuration,
        eligibilityBool: multiCarrierEligibility.multiCarrier
      })

    return store.update({
      eligibilityMonths: null,
      eligibilityBool: false
    })
  } else {
    return state
  }
}
