import type { JSONObject } from '@hc-frontend/utils';

export enum ICallAccountPayloadGender {
  Male = '1',
  Female = '0',
}
export interface ICallAccountPayload extends JSONObject {
  email: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  password: string;
  division: 'patient';
  gender: ICallAccountPayloadGender;
  phone: string;
  consented: boolean;
  createdBy: string;
}
