import { trackUserClick } from '@hc-frontend/third-party-snowplow'
import { SnowplowTrigger } from 'Snowplow'
import { getSPCategory } from '../../utils/getSPCategory'

function sendClickTrackEvent(clickEvent, trigger: SnowplowTrigger) {
  const element = clickEvent.currentTarget
  const placement = trigger?.placement ? trigger.placement : element.dataset.placement
  const description = trigger?.descriptionModifier
    ? trigger.descriptionModifier(element)
    : trigger.description
  const trackingData = {
    category: getSPCategory(),
    placement,
    action: trigger.eventType,
    label: element.textContent,
    value: trigger.valueParser
      ? trigger.valueParser(element.dataset.spValue)
      : element.dataset.spValue || ' ',
    elementId: element.id,
    targetUrl: element.href,
    description
  }

  trackUserClick(trackingData)
}

export { sendClickTrackEvent }
