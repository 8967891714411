import { v4 } from 'uuid'

export default function createSessionData(existingId?: string): Pivot.Session {
  return {
    sessionId: existingId || v4(),
    user: null,
    browser: null,
    quote: null,
    cart: null,
    createdAt: null,
    updatedAt: null,
    expiresAt: null
  }
}
