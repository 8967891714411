// cspell:disable
function initCustomHolder({ holder, destination, conversionid }) {
  this.module_idx += 1
  const h = this
  const $elem = window.__jq(holder),
    t = h.randomstring(5),
    i = h.setupmoduleid(h.getDataAttr($elem, 'module', '', !1)),
    n = h.getDataAttr($elem, 'showoninit', 'false', !0),
    e = h.getDataAttr($elem, 'calloninit', 'false', !0),
    s = h.getDataAttr($elem, 'showstatus', 'true', !0),
    o = h.getDataAttr($elem, 'ismobile', 'false', !0),
    l = h.getDataAttr($elem, 'conversionid', conversionid, !1),
    c = h.getDataAttr($elem, 'destination', destination, !1),
    a = h.getDataAttr($elem, 'destinationext', '', !1),
    d = $elem.html()
  $elem.attr('id', 'g3cm_holder_' + t),
    (h.modules[t] = {
      moduleid: i,
      loaded: !1,
      showoninit: 'true' == n,
      calloninit: 'true' == e,
      showstatus: 'true' == s,
      failback_html: d,
      ismobile: o,
      conversionid: l,
      destination: c,
      destinationext: a,
      hitid: '',
      ads: null
    }),
    h.single(t)
}

export default initCustomHolder
