import { propAccessor } from '@hc-frontend/utils'

function getPlanAsURL(state: Pivot.State): string {
  const plan = state.plans[state.planId]
  if (!plan?.multiCarrierPlan) {
    const complement = `&period=${propAccessor(
      state.filters,
      'period',
      ''
    )}&policies=${propAccessor(state.filters, 'policies', '')}`

    return `planid=${plan.planId}${complement}`
  } else {
    return `planid=${plan.planId}&period=120&policies=3`
  }
}

export default getPlanAsURL
