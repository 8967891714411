import type { RaygunV2 } from 'raygun4js'
import isNumber from 'utils/number/isNumber'
import fetch from 'unfetch'

declare const rg4js: RaygunV2

type RequestConfig = Partial<{
  method: string
  headers: Record<string, string>
  credentials: 'include' | 'omit'
  body: string
}>

export interface RequestDate {
  url: string
  responseOnError?: JSONValue
  data?: JSONValue
  isText?: boolean
  config?: RequestConfig
  timeout?: number
  severity?: string
}

class AjaxInvalidResponseError extends Error {
  status: number
  statusText: string
  responseText: string
  request: { config: RequestConfig; url: string }
  response: { responseText: string; statusText: string; status: number }

  constructor(res, config: RequestConfig, responseText: string) {
    super(`Error: ${res.status} - ${res.status}`)
    // follows the jquery ajax error schema - the same schema raygun uses to check ajax errors
    this.status = res.status
    this.statusText = res.statusText
    this.responseText = res.statusText
    // some additional info
    this.request = {
      url: res.url,
      config
    }
    this.response = {
      status: res.status,
      statusText: res.statusText,
      responseText: responseText
    }
  }
}

const fetchWithTimeout = async (url, config, timeout = 60_000) => {
  const controller = new AbortController()
  const timeoutId = isNumber(timeout) && setTimeout(() => controller.abort(), timeout)

  const response = await fetch(url, {
    ...config,
    signal: controller.signal
  })

  clearTimeout(timeoutId)

  return response
}
const DEFAULT_ERROR = 0
export const ERRORS_MAP: { [key: number]: string } = {
  [DEFAULT_ERROR]: 'Network Error',
  401: 'unauthorized'
}

// TODO: Implement this for every fetchJSONHandleError call
export type FetchJSONHandleErrorResponse<TData> = TData & { error?: string }

async function fetchJSONHandleError<T = any>({
  url,
  responseOnError,
  data,
  isText,
  config,
  timeout,
  severity = 'mid'
}: RequestDate): Promise<T | string | JSONValue> {
  config = config || {
    headers: {
      Accept: 'application/json'
    }
  }

  if (data) {
    config.headers['Content-Type'] = 'application/json'
    config.method = 'POST'
    config.body = JSON.stringify(data)
  }

  try {
    const response = await fetchWithTimeout(url, config, timeout)

    if (!response.ok) {
      const responseText = await response.text()
      throw new AjaxInvalidResponseError(response, config, responseText)
    }

    if (response.status === 204) {
      return ''
    }

    return isText ? await response.text() : await response.json()
  } catch (e) {
    let customData
    let notify = true
    if (e instanceof TypeError) {
      // > A fetch() promise will reject with a TypeError when a network error is encountered
      //   or CORS is misconfigured on the server-side
      //   https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch

      customData = { url, config, details: 'Network Error' }
    } else if (e instanceof DOMException && e.name === 'AbortError') {
      // > Note: When abort() is called, the fetch() promise rejects with a DOMException named AbortError.
      //   https://developer.mozilla.org/en-US/docs/Web/API/AbortController

      customData = { url, config, details: 'App aborted a request programmatically.' }
    } else if (e instanceof AjaxInvalidResponseError) {
      // status not in the range 200–299
      notify = e.status >= 500

      customData = {
        details: 'Request made and server responded',
        request: e.request,
        response: e.response
      }
    } else {
      customData = { url, config, details: 'Something happened' }
    }

    if (notify) {
      rg4js('send', {
        error: e,
        tags: ['handled_promise_rejection', `severity:${severity}`],
        customData
      })
    }
    const errorMessage = ERRORS_MAP[e.status] || ERRORS_MAP[DEFAULT_ERROR]
    return isText
      ? responseOnError
      : Object.assign({}, responseOnError, {
          error: errorMessage
        })
  }
}

export default fetchJSONHandleError
