import moment from 'moment-timezone'
import schedule from './schedule'

type Age = 'u65' | 'o65' | 'fe'

const toMinutes = ([h = 0, m = 0]: [int, int]): int => h * 60 + m
const daysOfTheWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
const computeHoursForSpecificDay = (day, timetable) => timetable[day] ?? timetable.default

export const getWeeklySchedule = (timetable = schedule.u65, days = daysOfTheWeek) => {
  return days.map(i => {
    const [start, end] = computeHoursForSpecificDay(i, timetable)

    return {
      day: moment()
        .isoWeekday(i)
        .format('dddd'),
      isClosed: start == null || end == null,
      from: moment(start, ['H mm']).format('h:mm A'),
      to: moment(end, ['H mm']).format('h:mm A')
    }
  })
}

export const computeHoursForToday = (t = moment(), age) => {
  const today = moment(t)
  const timetable = schedule[age] ?? schedule.u65
  const estDate = moment.tz(today, 'America/New_York')
  const day = daysOfTheWeek[estDate.day()]
  const estTimeInMinutes = estDate.hour() * 60 + estDate.minute()

  return {
    estTimeInMinutes,
    hours: computeHoursForSpecificDay(day, timetable)
  }
}

export default function salesCenterIsAvailable(age: Age, t = new Date()) {
  const {
    estTimeInMinutes,
    hours: [start, end]
  } = computeHoursForToday(t, age)

  if (start === undefined || end === undefined) {
    return false
  }

  return estTimeInMinutes >= toMinutes(start) && estTimeInMinutes < toMinutes(end)
}
