import type { PromNull } from '@hc-frontend/utils';
import { fetchJSON } from '@hc-frontend/utils';

import { geoIpURI } from '../config';
import type { IFetchLocationByIpServiceResponse } from './fetch-location-by-ip-service-response';

/**
 * Fetch the location using the current ip of the browser
 *
 * @public
 *
 * @returns The location information based on the current ip of the request or null if we can't determine the location
 *
 * @example
 * ```ts
 * const location = await fetchLocationByIpService();
 * ```
 */
async function fetchLocationByIpService(): PromNull<IFetchLocationByIpServiceResponse> {
  const { parsedBody } = await fetchJSON<IFetchLocationByIpServiceResponse>(
    geoIpURI()
  );

  return parsedBody?.ip ? parsedBody : null;
}

export { fetchLocationByIpService };
