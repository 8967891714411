import Templater from '@hc-frontend/shared-assets/src/js/utils/dom/Templater'
import getAgent from 'core/services/getAgent'
import getAgentHierarchy from 'core/services/getAgentHierarchy'
import getCoverageDuration from 'core/services/getCoverageDuration'
import getById from 'utils/DOM/getById'
import innerHTML from 'utils/DOM/innerHTML'
import toggleClass from 'utils/DOM/toggleClass'
import isSalesCenterSubDomain from 'utils/pivot/isSalesCenterSubDomain'

function getExtension(data, hierarchy) {
  const phoneLower = data.phone && data.phone.toLowerCase()
  if (phoneLower && phoneLower.includes('ext')) {
    const splitPhone = phoneLower.split('ext')
    const number = splitPhone[0].trim()
    let extension = splitPhone[1].trim()
    extension.includes('.') ? (extension = extension.replace(/\./, '')) : extension
    return { number, extension }
  }

  if (hierarchy[0] && hierarchy[0].phone_extension) {
    const number = data.phone
    const extension = hierarchy[0].phone_extension
    return { number, extension }
  }
  return { number: data.phone }
}

function setUpAgentsHeader(hcData) {
  const promises = [getAgent(hcData.agent_id), getAgentHierarchy(hcData.agent_id)]
  const stateAcronym = hcData.location.state_acronym
  if (
    stateAcronym &&
    /census\/|quotes\/|medicare-supplement\//.test(window.location.pathname)
  ) {
    promises.push(getCoverageDuration(stateAcronym, hcData.sDate))
  }

  return Promise.all(promises).then(([agentResponse, hierarchy, coverageResponse]) => {
    const agentData = agentResponse?.data
    if (!agentData) {
      return
    }
    const phoneData = getExtension(agentData, hierarchy)
    agentData.completePhone = phoneData.number
    if (phoneData.extension) {
      agentData.completePhone += ' ext. ' + phoneData.extension
      agentData.extension = phoneData.extension
      agentData.phone = phoneData.number
    }
    const isSalesCenter = isSalesCenterSubDomain()
    const logoTemplate = Templater(getById('agent-logo-tpl')?.innerText)
    const contactTemplate = Templater(getById('agent-contact-tpl')?.innerText)
    getById('Header__logo')?.insertAdjacentHTML('afterend', logoTemplate(agentData))
    innerHTML('#headerCall', contactTemplate({ ...agentData, isSalesCenter }))
    if (!isSalesCenter) {
      innerHTML('.call-an-agent-btn', `Call ${agentData.full_name}`)
    }
    toggleClass('#wrap-phoneExt', 'd-none', !agentData.extension)

    return agentData
  })
}

export default setUpAgentsHeader
