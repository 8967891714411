import loadScript from '@hc-frontend/shared-assets/src/js/utils/dom/loadScript'
import salesCenterIsAvailable from 'utils/pivot/salesCenterIsAvailable'
import connectMeStore from './connectMeStore'
import toggleConnectMeEnabledClass from './toggleConnectMeEnabledClass'

let loadPromise: Promise<any>
const initStore = {
  ready: false,
  salesCenterAvailable: salesCenterIsAvailable(),
  callFormSubmitted: false,
  modules: {}
}

connectMeStore.registry(toggleConnectMeEnabledClass)
connectMeStore.update(initStore)

function loadConnectMe() {
  if (loadPromise) return loadPromise
  loadPromise = loadConnectMeScript({ loadJquery: 'true' }).then(() => {
    connectMeStore.update({
      ready: true
    })
  })
  return loadPromise
}

/**
 * Loads ConnectMe script if it is not already loaded.
 * @returns {Promise}
 */
function loadConnectMeScript({ loadJquery = 'false' } = {}) {
  window.g3cm =
    window.g3cm ||
    function () {
      ;(window.g3cm.q = window.g3cm.q || []).push(arguments)
    }
  window.g3cm('loadjquery', loadJquery) // cspell:disable-line

  return new Promise(resolve => {
    if (typeof window.G3ConnectMe !== 'function') {
      loadScript('//api.connectme.gen3ventures.com/js/connectme-v2.min.js', true, resolve)
    } else {
      resolve(void 0)
    }
  })
}

export default loadConnectMe
