import invoke from '@hc-frontend/shared-assets/src/js/utils/fp/invoke'

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/Events/scroll
 */
function onOutOfView(elemId, callback, offset = {}) {
  let lastKnownScrollPosition = 0
  let lastKnownBottomScrollPosition
  let ticking = false
  const {
    scrollReachElement = 0,
    isOutOfView = 0,
    isIntoView = 0,
    scrollBottomPosition = null,
    customOriginalTop = null,
    container = window
  } = offset
  const $elem = $(`#${elemId}`)
  const funcToCall = invoke(callback)
  const heightElement = $elem.outerHeight(true)
  const positionMethod = container instanceof Window ? 'offset' : 'position'
  const originalTop = customOriginalTop || $elem[positionMethod]().top
  const $window = $(container)
  const windowHeight = $window.height()

  $window.off(`scroll.${elemId}OutView`).on(`scroll.${elemId}OutView`, () => {
    if (!ticking) {
      window.requestAnimationFrame(function() {
        lastKnownScrollPosition = $window.scrollTop()
        lastKnownBottomScrollPosition = lastKnownScrollPosition + windowHeight
        const elemTop = $elem[positionMethod]().top
        const elemBottom = elemTop + heightElement

        funcToCall({
          isOutOfView: elemBottom + isOutOfView <= lastKnownScrollPosition,
          isIntoView: elemTop + isIntoView <= lastKnownBottomScrollPosition,
          scrollReachElement: originalTop + scrollReachElement <= lastKnownScrollPosition,
          scrollBottomReachPosition:
            scrollBottomPosition && scrollBottomPosition <= lastKnownBottomScrollPosition,
          scrollBottomReachElemBottom: elemBottom <= lastKnownBottomScrollPosition,
          lastKnownScrollPosition,
          elemTop,
          elemBottom
        })
        ticking = false
      })

      ticking = true
    }
  })

  return {
    originalTop,
    off: () => {
      $window.off(`scroll.${elemId}OutView`)
    }
  }
}

export default onOutOfView
