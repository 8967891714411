import * as proxyStorage from 'proxy-storage'
const handlers = {}

function dispatch([command, ...args]) {
  try {
    if (handlers[command]) {
      handlers[command](proxyStorage.default.getItem('hc'), ...args)
    }
  } catch (e) {} // eslint-disable-line
}

function registry(command, handler) {
  handlers[command] = handler
}

export default {
  registry,
  dispatch
}
