import { SnowplowTrigger } from 'Snowplow'
import { sendClickTrackEvent } from './sendClickTrackEvent'

function registerSnowplowTriggers(triggers: SnowplowTrigger[]) {
  const body = $('body')
  triggers.forEach(trigger => {
    body.on(trigger.elementTrigger, trigger.element, event => {
      sendClickTrackEvent(event, trigger)
    })
  })
}

export { registerSnowplowTriggers }
