import fetchJSONHandleError from 'utils/NET/fetchJSONHandleError'
import { SESSION_MGMT_DOMAIN } from 'core/constants/endpoints'

export default function load(sessionId: string) {
  const url = `${SESSION_MGMT_DOMAIN}/api/v1/session?sessionId=${sessionId}`

  return fetchJSONHandleError({
    url
  })
}
