import invoke from '@hc-frontend/shared-assets/src/js/utils/fp/invoke'
import exposeGlobalFunctionAndExecuteQueue from '@hc-frontend/shared-assets/src/js/utils/dom/exposeGlobalFunctionAndExecuteQueue'

const queue = []

exposeGlobalFunctionAndExecuteQueue(callback => {
  queue.push(callback)
}, 'appStateUpdatedQueue')

function appStateUpdated(state, prevState) {
  queue.forEach(callback => {
    setTimeout(() => {
      invoke(callback)(state, prevState)
    }, 0)
  })
}

export default appStateUpdated
