import addEventListener from 'utils/DOM/addEventListener'
import buildControllerZip from 'core/controllers/buildControllerZip'
import noop from '@hc-frontend/shared-assets/src/js/utils/fp/noop'
import { IS_ENROLL } from 'core/constants/environment'
import clickEventName from 'utils/DOM/clickEventName'
import toggleClass from 'utils/DOM/toggleClass'

const body = document.body
const form = document.forms.form
const SHOW_CLASS = 'show'
const LOADING_CLASS = 'loading'
const addOverlay = () => toggleClass(body, SHOW_CLASS, true)
let focusAuto = true
let callAfterLoading

const zip = buildControllerZip({
  input: form.zip,
  callStartLoading: () => {
    toggleClass(form, LOADING_CLASS, true)
    form.submit.disabled = true
  },
  callEndLoading: state => {
    toggleClass(form, LOADING_CLASS, false)
    form.submit.disabled = false
    if (focusAuto) {
      form.zip.focus()
    }
    callAfterLoading?.(state)
  }
})

const removeOverlay = () => {
  form.zip.blur()
  toggleClass(body, SHOW_CLASS, false)
}

function handleSubmit(e) {
  const statusZip = zip.getStatus()
  if (statusZip.valid) {
    if (IS_ENROLL) {
      const popup = form.dataset.popup || form.action
      window.open(`${popup}?zip=${form.zip.value}`, '_blank')
      form.dataset.popup = popup
      form.action = form.getAttribute('data-action')
    }
  } else {
    zip.validate()
    e.preventDefault()
  }
}

addEventListener('#wrap-zip', 'submit', handleSubmit)

$(body)
  .on(clickEventName, '.overlay', removeOverlay)
  .on(clickEventName, '#zip', () => {
    zip.enableOnBlur()
    addOverlay()
  })

function updateZipFormFromData({ hcStorage, autofocus = true, callback = noop }) {
  callAfterLoading = callback
  focusAuto = autofocus
  if (hcStorage.location.zip_code) {
    zip.setValue(hcStorage.location.zip_code)
  }

  if (autofocus && hcStorage.Device !== 'Mobile') {
    form.zip.focus()
  }

  return hcStorage
}

export default updateZipFormFromData
