function once(func) {
  let result

  return function () {
    if (func) {
      result = func.apply(this, arguments) // eslint-disable-line
      func = null
    }

    return result
  }
}

export default once
