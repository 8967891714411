import { ConditionalContextProvider } from '@snowplow/browser-tracker'
import { PHChannelDataProvider } from 'Snowplow'
import {
  phChannelContextFilter,
  phChannelContextGenerator
} from './phChannelContextProvider'

function phChannelContextProvider(
  dataProvider: PHChannelDataProvider
): ConditionalContextProvider {
  return [phChannelContextFilter(dataProvider), phChannelContextGenerator(dataProvider)]
}

export { phChannelContextProvider }
