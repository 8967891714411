import fetchJSONHandleError from 'utils/NET/fetchJSONHandleError'
import { SESSION_MGMT_DOMAIN } from 'core/constants/endpoints'

import { SessionMgmtDTO } from './types'

export default function save(data: SessionMgmtDTO) {
  const url = `${SESSION_MGMT_DOMAIN}/api/v1/session`

  return fetchJSONHandleError({
    url,
    data
  })
}
